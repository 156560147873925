import { FC, createContext, useState } from "react";
import { ETalentType, ICtxComponent, ITalentContext } from "../../models";

export const TalentContext = createContext<ITalentContext>({
  talentType: ETalentType.bots,
  setTalentType: () => {},
});

const TalentProvider: FC<ICtxComponent> = ({ children }) => {
  const [talentType, setTalentType] = useState<ETalentType>(ETalentType.bots);

  return (
    <TalentContext.Provider
      value={{
        talentType,
        setTalentType,
      }}
    >
      {children}
    </TalentContext.Provider>
  );
};

export default TalentProvider;
