import { FC, createContext, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  ICategoryProperty,
  ICtxComponent,
  IFirebaseContext,
  ILifeCost,
  ISubcategory,
  ITask,
} from "../../models";

const config = {
  apiKey: process.env.REACT_APP_FIRE_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_MEASUREMENT_ID,
};

export const FirebaseContext = createContext<IFirebaseContext>({
  app: null,
  auth: null,
  db: null,
  analytics: null,

  categories: [],
  setCategories: () => {},

  subcategories: [],
  setSubcategories: () => {},

  lifeCost: [],
  setLifeCost: () => {},

  tasks: [],
  setTasks: () => {},

  authLoading: false,
  setAuthLoading: () => {},
});

export const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

const FirebaseProvider: FC<ICtxComponent> = ({ children }) => {
  // States
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<ICategoryProperty[]>([]);
  const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
  const [lifeCost, setLifeCost] = useState<ILifeCost[]>([]);
  const [tasks, setTasks] = useState<ITask[]>([]);

  return (
    <FirebaseContext.Provider
      value={{
        app,
        db,
        auth,
        analytics,

        categories,
        setCategories,

        subcategories,
        setSubcategories,

        lifeCost,
        setLifeCost,

        tasks,
        setTasks,

        authLoading,
        setAuthLoading,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
