import { useContext } from "react";
import {
  /* getDocs,*/
  collection,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { FirebaseContext } from "../contexts/Firebase";
import { useAuth } from "../modules/auth";
import { notify } from "react-notify-toast";
import { IFirebaseContext } from "../../models";
import {
  GoogleAuthProvider,
  // EmailAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  // fmtCategories,
  // fmtLifeCost,
  // fmtSubcategories,
  fmtUser,
  lifeCostSort,
} from "../../utils/functions";

import { api } from "../../services/backend";

const providers = {
  google: new GoogleAuthProvider(),
};

const verifyName = (value: any, first: boolean = true) => {
  const isString = typeof value === "string";
  const isInvalid = [null, undefined, "", false].includes(value);
  if (isInvalid || !isString) return "";
  const splitted = value.split(" ");
  return first ? splitted[0] : splitted[1];
};

const useFirebase = () => {
  const {
    app,
    auth,
    db,
    analytics,

    categories,
    setCategories,

    subcategories,
    setSubcategories,

    lifeCost,
    setLifeCost,

    tasks,
    setTasks,

    authLoading,
    setAuthLoading,
  } = useContext<IFirebaseContext>(FirebaseContext);

  const { setCurrentUser, saveAuth } = useAuth();

  const setNewUser = (user: any) => {
    const auth = {
      api_token: user.uid,
      refreshToken: user.refreshToken,
    };

    setCurrentUser({
      id: `${user.uid}`,
      username: `${user.email}`,
      password: undefined,
      email: `${user.email}`,
      first_name: `${verifyName(user?.displayName)}`,
      last_name: `${verifyName(user?.displayName, false)}`,
      fullname: ![null, undefined, ""].includes(user?.displayName)
        ? user?.displayName
        : "",
      phone: ![null, undefined, ""].includes(user?.phoneNumber)
        ? user?.phoneNumber
        : "",
      pic: ![null, undefined, ""].includes(user?.photoURL)
        ? user?.photoURL
        : "",
      language: "es",
      auth,
    });

    saveAuth(auth);
  };

  const getConfig = async () => {
    try {
      const response: any = (
        await api.request({
          url: "/init",
          method: "get",
        })
      ).data;

      const { data } = response;

      setCategories(data.categories);

      setSubcategories(data.subcategories);

      setLifeCost(lifeCostSort(data.lifeCost));

      setTasks(data.tasks);
    } catch (e) {
      console.error("useFirebase:getConfig - ", e);
    }
  };

  const addNewConfig = async (object: any) => {
    try {
      const docRef = await addDoc(collection(db, "subcategories"), object);
      console.log(docRef.id);
    } catch (e) {
      console.error("useFirebase:addNewConfig - ", e);
    }
  };

  const checkIfEmailExistInDB = async (email: string | null) => {
    let exist: boolean = false;
    try {
      await db.collection("users").where("email", "==", email).get();
      exist = true;
    } catch {
      exist = false;
    }

    return exist;
  };

  const signInWithGoogle = async () => {
    let exist: boolean = false;
    setAuthLoading(true);
    try {
      const { user } = await signInWithPopup(auth, providers.google);
      exist = await checkIfEmailExistInDB(user.email);
      if (exist) {
        throw new Error("User exist");
      }

      const copyUser = fmtUser(user);
      await setDoc(doc(db, "users", user.uid), {
        ...copyUser,
        provider: "google",
      });

      console.log(`Usuario ${user.displayName} inició sesión con Google`);
      setNewUser(user);
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      notify.show(errorMessage, "error", 3000);
      console.log(
        `Error al iniciar sesión con Google: ${errorCode} - ${errorMessage}`
      );
    } finally {
      setAuthLoading(false);
    }
  };

  const signInWithEmail = async (email: string, password: string) => {
    setAuthLoading(true);
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      console.log(`Usuario ${user.email} inició sesión con Email`);
      setNewUser(user);
    } catch (error: any) {
      const msg = `Error al iniciar sesión con Google: ${error?.code} - ${error?.message}`;
      console.error(msg);
      notify.show(msg, "error", 3000);
    } finally {
      setAuthLoading(false);
    }
  };

  const registerWithEmail = async (email: string, password: string) => {
    let exist: boolean = false;
    setAuthLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (exist) {
        throw new Error("User exist");
      }

      const copyUser = fmtUser(user);
      await setDoc(doc(db, "users", user.uid), {
        ...copyUser,
        provider: "email",
      });

      setNewUser(user);
    } catch (error: any) {
      const msg = `Error al registrarse con Email: ${error?.code} - ${error?.message}`;
      console.error(msg);
      notify.show(msg, "error", 3000);
    } finally {
      setAuthLoading(false);
    }
  };

  return {
    app,
    auth,
    db,
    analytics,

    /* Categories */
    categories,

    /* Subcategories */
    subcategories,

    /* lifeCost */
    lifeCost,

    /* lifeCost */
    tasks,

    /* Auth */
    authLoading,

    /* handlers */
    getConfig,
    addNewConfig,
    signInWithGoogle,
    signInWithEmail,
    registerWithEmail,
  };
};

export default useFirebase;
