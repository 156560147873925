import { Route, Routes } from "react-router-dom";
import { AuthPage } from "../modules/auth";
import { CalculatorPage } from "../modules/calculator";
import { TalentPage } from "../modules/talent";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path="calculator/*" element={<CalculatorPage />} />
        <Route path="talent/*" element={<TalentPage />} />
        <Route path="auth/*" element={<AuthPage />} />
      </Route>
    </Routes>
  );
};

export { PublicRoutes };
