/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import useFirebase from "../../../hooks/useFirebase";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  // email: "admin@demo.com",
  // password: "demo",
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const { saveAuth, auth: appAuth } = useAuth();
  const { signInWithGoogle, signInWithEmail, authLoading } = useFirebase();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // setLoading(true);
      try {
        // const { data: auth } = await login(values.email, values.password);
        // saveAuth(auth);
        // const { data: user } = await getUserByToken(auth.api_token);
        // setCurrentUser(user);
        signInWithEmail(values.email, values.password);
        saveAuth(appAuth);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        // setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10 d-flex flex-column align-items-center">
        <img
          src={toAbsoluteUrl("/media/logos/tigre.svg")}
          alt={"sit"}
          width={200}
          className={"pb-5"}
        />

        <div className="text-400 fw-bold fs-1 mw-400px pb-5">
          Estamos cambiando la manera en que trabajan los Freelancers.
          {/*
            <Link
              to="/auth/login"
              className="link-primary fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
          */}
        </div>

        <div className="text-gray-400 fw-bold fs-4 mb-5">
          {"¿Eres nuevo por aquí? "}
          <Link
            to="/auth/registration"
            className="link-primary fw-bolder"
            style={{ marginLeft: "5px" }}
          >
            Registrate
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            {/* end::Label */}
            {/* begin::Link 
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
             */}
          </div>
        </div>
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!authLoading && <span className="indicator-label">Continue</span>}
          {authLoading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        <div className="d-flex align-items-center mb-10">
          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
          <span className="fw-bold text-gray-400 fs-7 mx-2">O</span>
          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
        </div>

        {/* begin::Google link */}
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn fw-bolder w-70 mb-10 bg-red"
            style={{ border: "1px solid silver" }}
            onClick={() => {
              signInWithGoogle();
              saveAuth(appAuth);
            }}
          >
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
              className="h-20px me-3"
            />
            Inicia sesión con Google
          </button>
        </div>

        {/*
        <div className="text-gray-400 fw-bold fs-4 mb-5">
          <Link
            to="/auth/login"
            className="link-primary fw-bolder"
            style={{ marginLeft: "5px" }}
          >
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
        */}

        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a
          href="#"
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/facebook-4.svg")}
            className="h-20px me-3"
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/apple-black.svg")}
            className="h-20px me-3"
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  );
}
