/* eslint-disable react/jsx-no-target-blank */
// import { useIntl } from "react-intl";
// import { AsideMenuItemWithSubMain } from "./AsideMenuItemWithSubMain";
// import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  // const intl = useIntl();
  return (
    <>
      {/*
      <AsideMenuItem
        to="/dashboard"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-bar-chart-line"
        bsTitle={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        className="py-3"
      />
      */}

      <AsideMenuItem
        to="/calculator"
        title=""
        bsTitle="Inicio"
        icon="/media/svg/custom/home.svg"
        className="py-3 m-auto"
      />

      {/*
      <AsideMenuItem
        to="/calculator"
        title=""
        bsTitle="Pagos"
        icon="/media/svg/custom/payments.svg"
        className="py-3 m-auto"
      />
      */}

      {/*
      <AsideMenuItem
        to="/talent"
        title=""
        bsTitle="Contratos"
        icon="/media/svg/custom/contracts.svg"
        className="py-3 m-auto"
      />
      */}

      {/*
      <AsideMenuItem
        to="/talent"
        title=""
        bsTitle="Certificados"
        icon="/media/svg/custom/certificates.svg"
        className="py-3 m-auto mb-20"
      />
      */}
      <AsideMenuItem
        to="/calculator"
        title=""
        bsTitle="Certificados: Disponible muy pronto..."
        icon="/media/svg/custom/certificates.svg"
        className="py-3 m-auto mb-20"
      />

      {/*
      <AsideMenuItem
        to="/calculator"
        title=""
        bsTitle="Configuración"
        icon="/media/svg/custom/gear.svg"
        className="py-3 m-auto"
      />
      */}

      {/*
      <AsideMenuItem
        to="/talent"
        title=""
        bsTitle="Alertas"
        icon="/media/svg/custom/warn.svg"
        className="py-3 m-auto"
      />
      */}

      {/*
      <AsideMenuItemWithSubMain
        to="/crafted/pages"
        title="Crafted"
        fontIcon="bi-file-text"
        bsTitle="Crafted"
      >
        <AsideMenuItemWithSub
          to="/crafted/pages/profile"
          title="Profile"
          hasBullet={true}
        >
          <AsideMenuItem
            to="/crafted/pages/profile/overview"
            title="Overview"
            bsTitle="Overview"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/projects"
            title="Projects"
            bsTitle="Projects"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/campaigns"
            title="Campaigns"
            bsTitle="Campaigns"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/documents"
            title="Documents"
            bsTitle="Documents"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/connections"
            title="Connections"
            hasBullet={true}
            bsTitle="Connections"
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to="/crafted/pages/wizards"
          title="Wizards"
          hasBullet={true}
        >
          <AsideMenuItem
            to="/crafted/pages/wizards/horizontal"
            title="Horizontal"
            hasBullet={true}
            bsTitle="Horizontal"
          />
          <AsideMenuItem
            to="/crafted/pages/wizards/vertical"
            title="Vertical"
            bsTitle="Vertical"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to="/crafted/accounts"
          title="Accounts"
          hasBullet={true}
        >
          <AsideMenuItem
            to="/crafted/account/overview"
            title="Overview"
            hasBullet={true}
            bsTitle="Overview"
          />
          <AsideMenuItem
            to="/crafted/account/settings"
            title="Settings"
            hasBullet={true}
            bsTitle="Settings"
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to="/crafted/widgets"
          title="Widgets"
          hasBullet={true}
        >
          <AsideMenuItem
            to="/crafted/widgets/lists"
            title="Lists"
            bsTitle="Lists"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/statistics"
            title="Statistics"
            bsTitle="Statistics"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/charts"
            title="Charts"
            bsTitle="Charts"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/mixed"
            title="Mixed"
            bsTitle="Mixed"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/tables"
            title="Tables"
            bsTitle="Tables"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/feeds"
            title="Feeds"
            bsTitle="Feeds"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to="/apps/chat" title="Chat" hasBullet={true}>
          <AsideMenuItem
            to="/apps/chat/private-chat"
            title="Private Chat"
            bsTitle="Private Chat"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/apps/chat/group-chat"
            title="Group Chart"
            bsTitle="Group Chart"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/apps/chat/drawer-chat"
            title="Drawer Chart"
            bsTitle="Drawer Chart"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSubMain>
      */}

      {/* 
      <AsideMenuItemWithSubMain
        to="/error"
        title="Errors"
        fontIcon="bi-sticky"
        bsTitle="Errors"
      >
        <AsideMenuItem to="/error/404" title="Error 404" hasBullet={true} />
        <AsideMenuItem to="/error/500" title="Error 500" hasBullet={true} />
      </AsideMenuItemWithSubMain>
      */}

      {/* 
      <AsideMenuItem
        to="/apps/user-management/users"
        title="User management"
        fontIcon="bi-people"
        bsTitle="User management"
        className="py-3"
      />
      <AsideMenuItem
        outside={true}
        to={process.env.REACT_APP_PREVIEW_DOCS_URL + "/docs/changelog"}
        title="User management"
        fontIcon="bi-card-text"
        bsTitle={`Changelog ${process.env.REACT_APP_VERSION}`}
        className="py-3"
      />
      */}
    </>
  );
}
