/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { getAuth } from "../auth";
import { MasterLayout } from "../../../_metronic/layout/MasterLayout";
import Talent from "./components/Talent";

const TalentPage = () => {
  const isAuth = getAuth();

  return (
    <Routes>
      <Route element={isAuth ? <Talent /> : <MasterLayout />}>
        <Route index element={<Talent />} />
        <Route path="bots" element={<Talent />} />
      </Route>
    </Routes>
  );
};

export { TalentPage };
