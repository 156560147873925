import { useContext } from "react";
import { ITalentContext } from "../contexts/models";
import { TalentContext } from "../contexts/Talent";

const useTalent = () => {
  const { talentType, setTalentType } =
    useContext<ITalentContext>(TalentContext);

  return {
    talentType,
    setTalentType,
  };
};

export default useTalent;
