import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import CalculatorProvider from "./contexts/Calculator";
import FirebaseProvider from "./contexts/Firebase";
import Notifications from "react-notify-toast";
import "../styles/custom.css";
import TalentProvider from "./contexts/Talent";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <FirebaseProvider>
          <LayoutProvider>
            <AuthInit>
              <CalculatorProvider>
                <TalentProvider>
                  <Notifications />
                  <Outlet />
                  <MasterInit />
                </TalentProvider>
              </CalculatorProvider>
            </AuthInit>
          </LayoutProvider>
        </FirebaseProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
