import { FC } from "react";
import { getSalaryHours } from "../../../../utils/functions";
import styles from "./styles.module.scss";
import { ECalculatorType } from "../../../../models";

export interface ISalaryIndicator {
  salary: number;
  calculatorType: string;
  country: any;
  salaryImp: any;
  isChecked: boolean;
}

const SalaryIndicator: FC<ISalaryIndicator> = ({
  salary,
  calculatorType,
  country,
  salaryImp,
  isChecked,
}) => {
  return (
    <>
      {salary !== 0 && (
        <>
          {calculatorType === ECalculatorType.hour && (
            <div className={`d-flex align-items-center justify-content-center`}>
              <div className={`${styles.minisquare} ${styles.green}`}>
                <div>
                  <p className="fw-bold">Promedio Hora</p>
                  <div className={styles.indicator}>
                    <p>
                      {`$${getSalaryHours(
                        !["0", "0.00", 0].includes(salaryImp)
                          ? salaryImp
                          : salary
                      )}`}
                    </p>
                    <p>la Hora</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.minisquare} ${styles.orange}`}>
                <div>
                  <p className="fw-bold">Costo de la vida</p>
                  {country !== undefined ? (
                    <div className={styles.indicator}>
                      <p>
                        {`$${getSalaryHours(country.month + country.rent)}`}
                      </p>
                      <p>la Hora</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: 79,
                        fontSize: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Ubicación no Seleccionada
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {calculatorType === ECalculatorType.salary && (
            <div className={`d-flex align-items-center justify-content-center`}>
              <div className={`${styles.minisquare} ${styles.green}`}>
                <div>
                  <p className="fw-bold">Promedio Sueldo</p>
                  <div className={styles.indicator}>
                    <p>
                      {`$${
                        ["0", "0.00", 0].includes(salaryImp)
                          ? salary.toFixed(2)
                          : salaryImp.toFixed(2)
                      }`}
                    </p>
                    <p>por Mes</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.minisquare} ${styles.orange}`}>
                <div>
                  <p className="fw-bold">Sueldo Calculado</p>
                  {![undefined, ""].includes(country?.country) ? (
                    <div className={styles.indicator}>
                      <p>
                        {`$${
                          country && isChecked
                            ? country.month + country.rent
                            : country?.month
                        }`}
                      </p>
                      <p>por Mes</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: 79,
                        fontSize: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Ubicación no Seleccionada
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SalaryIndicator;
