import styles from "./styles.module.scss";

const Disclaimer = () => {
  return (
    <div className={`w-100 m-0${styles.bgOrange}`}>
      <div className="w-100">
        <h1>Disclaimer</h1>
        <p>Updated at 2023-02-27</p>

        <p>
          Tigrenator hereby grants you access to tigrenator.com (“the Website”)
          and invites you to purchase the services offered here.
        </p>

        <h1>Definitions and key terms</h1>
        <p>
          To help explain things as clearly as possible in this Disclaimer,
          every time any of these terms are referenced, are strictly defined as:
        </p>
        <ul>
          <li>
            Cookie: small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, remember information about you such as your language
            preference or login information.
          </li>
          <li>
            Company: when this policy mentions “Company,” “we,” “us,” or “our,”
            it refers to TIgrenator Global Corporation, Beaverton, Oregon 9450
            SW Gemini Dr PMB 79951 Beaverton, Oregon 97008-7105 US that is
            responsible for your information under this Disclaimer.
          </li>
          <li>
            Service: refers to the service provided by Tigrenator as described
            in the relative terms (if available) and on this platform.
          </li>
          <li>
            Website: site, which can be accessed via this URL: tigrenator.com
          </li>
          <li>
            You: a person or entity that is registered with Tigrenator to use
            the Services.
          </li>
        </ul>
        <p>
          This Disclaimer was created with{" "}
          <a href="https://termify.io" target="_blank" rel="noreferrer">
            Termify
          </a>
          .
        </p>

        <h1>Limited liability</h1>
        <p>
          Tigrenator endeavours to update and/or supplement the content of the
          website on a regular basis. Despite our care and attention, content
          may be incomplete and/or incorrect.
        </p>
        <p>
          The materials offered on the website are offered without any form of
          guarantee or claim to their correctness. These materials can be
          changed at any time without prior notice from Tigrenator.
        </p>
        <p>
          Particularly, all prices on the website are stated subject to typing
          and programming errors. No liability is assumed for the implications
          of such errors. No agreement is concluded on the basis of such errors.
        </p>
        <p>
          Tigrenator shall not bear any liability for hyperlinks to websites or
          services of third parties included on the website. From our website,
          you can visit other websites by following hyperlinks to such external
          sites. While we strive to provide only quality links to useful and
          ethical websites, we have no control over the content and nature of
          these sites. These links to other websites do not imply a
          recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone ‘bad’.
        </p>
        <p>
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their "Terms of Service" before engaging in any business or
          uploading any information.
        </p>
        <h1>Links to Other Websites Disclaimer</h1>
        <p>
          This Disclaimer applies only to the Services. The Services may contain
          links to other websites not operated or controlled by Tigrenator. We
          are not responsible for the content, accuracy or opinions expressed in
          such websites, and such websites are not investigated, monitored or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Privacy
          Policy is no longer in effect. Your browsing and interaction on any
          other website, including those that have a link on our platform, is
          subject to that website’s own rules and policies. Such third parties
          may use their own cookies or other methods to collect information
          about you. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy and Terms of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h1>Errors and Omissions Disclaimer</h1>
        <p>
          Tigrenator is not responsible for any content, code or any other
          imprecision.
        </p>
        <p>Tigrenator does not provide warranties or guarantees.</p>
        <p>
          In no event shall Tigrenator be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. Tigrenator reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </p>

        <h1>General Disclaimer</h1>
        <p>
          The Tigrenator Service and its contents are provided "as is" and "as
          available" without any warranty or representations of any kind,
          whether express or implied. Tigrenator is a distributor and not a
          publisher of the content supplied by third parties; as such,
          Tigrenator exercises no editorial control over such content and makes
          no warranty or representation as to the accuracy, reliability or
          currency of any information, content, service or merchandise provided
          through or accessible via the Tigrenator Service. Without limiting the
          foregoing, Tigrenator specifically disclaims all warranties and
          representations in any content transmitted on or in connection with
          the Tigrenator Service or on sites that may appear as links on the
          Tigrenator Service, or in the products provided as a part of, or
          otherwise in connection with, the Tigrenator Service, including
          without limitation any warranties of merchantability, fitness for a
          particular purpose or non-infringement of third party rights. No oral
          advice or written information given by Tigrenator or any of its
          affiliates, employees, officers, directors, agents, or the like will
          create a warranty. Price and availability information is subject to
          change without notice. Without limiting the foregoing, Tigrenator does
          not warrant that the Tigrenator Service will be uninterrupted,
          uncorrupted, timely, or error-free.
        </p>
        <h1>Copyright Disclaimer</h1>
        <p>
          All intellectual property rights concerning these materials are vested
          in Tigrenator. Copying, distribution and any other use of these
          materials is not permitted without the written permission of
          Tigrenator, except and only to the extent otherwise provided in
          regulations of mandatory law (such as the right to quote), unless
          otherwise stated for certain materials.
        </p>
        <h1>Financial Disclosure</h1>
        <p>
          Gross merchandise sales is a non-GAAP metric. We use it to express the
          total demand across all of our web sites and stores. This number
          measures the dollar value of the order placed in the year before
          accruing for certain items such as returns, and it ignores certain
          timing cut-offs that are required by GAAP for revenue recognition
          purposes. If we were a public company, we would have to reconcile
          gross merchandise sales to the nearest GAAP metric (net sales), but we
          are currently a private company so the gross merchandise sales number
          should be viewed just as an interesting number that we want to share
          with our friends.
        </p>
        <p>
          This document contains forward-looking statements that involve risks
          and uncertainties, as well as assumptions that, if they ever
          materialise or prove incorrect, could cause or results to differ
          materially from those expressed or implied by the forward-looking
          statements and assumptions. These risks and uncertainties include, but
          are not limited to, the risk of economic slowdown, the risk of over or
          underbuying, the risk of consumers not shopping online at our website
          at the rate we expected, the risk of supplier shortages, the risk of
          new or growing competition, the risk of natural or some other type of
          disaster affecting our fulfilment of historical operations or web
          servers, and the risk of the world generally coming to an end. All
          statements other than statements historical fact are statements that
          could be deemed forward-looking statements, including statements of
          expectation or belief; and any statement of assumptions underlying any
          of the foregoing. Tigrenator assumes no obligation and does not intend
          to update these forward-looking statements.
        </p>
        <h1>Educational Disclosure</h1>
        <p>
          Any Information provided by Tigrenator is for educational purposes
          only, and is not to be interpreted as a recommendation for a specific
          treatment plan, product, or course of action. Tigrenator is a
          distributor and not a publisher of the content supplied by third
          parties; as such, Tigrenator exercises no editorial control over such
          content and makes no warranty or representation as to the accuracy,
          reliability or currency of any information or educational content
          provided through or accessible via Tigrenator. Without limiting the
          foregoing, Tigrenator specifically disclaims all warranties and
          representations in any content transmitted on or in connection with
          Tigrenator or on sites that may appear as links on Tigrenator, or in
          the products provided as a part of, or otherwise in connection with,
          the Tigrenator. No oral advice or written information given by
          Tigrenator or any of its affiliates, employees, officers, directors,
          agents, or the like will create a warranty.
        </p>
        <h1>Testimonials Disclosure</h1>
        <p>
          Any testimonials provided on this platform are opinions of those
          providing them. The information provided in the testimonials is not to
          be relied upon to predict results in your specific situation. The
          results you experience will be dependent on many factors including but
          not limited to your level of personal responsibility, commitment, and
          abilities, in addition to those factors that you and/or Tigrenator may
          not be able to anticipate.
        </p>
        <p>
          We will give honest testimonials to our visitors regardless of any
          discount. Any product or service that we test are individual
          experiences, reflecting real life experiences. The testimonials could
          be displayed on audio, text or video and are not necessarily
          representative of all of those who will use our products and/or
          services.
        </p>
        <p>
          Tigrenator does not guarantee the same results as the testimonials
          given on our platform. Testimonials presented on Tigrenator are
          applicable to the individuals writing them, and may not be indicative
          of future success of any other individuals.
        </p>
        <p>
          Please don’t hesitate to contact us if you would like to know more
          about testimonials, discounts, or any of the products/services that we
          review.
        </p>
        <h1>Your Consent</h1>
        <p>
          We've updated our Disclaimer to provide you with complete transparency
          into what is being set when you visit our site and how it's being
          used. By using our website, registering an account, or making a
          purchase, you hereby consent to our Disclaimer and agree to its terms.
        </p>

        <h1>Changes To Our Disclaimer</h1>
        <p>
          Should we update, amend or make any changes to this document so that
          they accurately reflect our Service and policies. Unless otherwise
          required by law, those changes will be prominently posted here. Then,
          if you continue to use the Service, you will be bound by the updated
          Disclaimer. If you do not want to agree to this or any updated
          Disclaimer, you can delete your account.
        </p>
        <h1>Contact Us</h1>
        <p>
          Don't hesitate to contact us if you have any questions regarding this
          Disclaimer.
        </p>
        <ul>
          <li>
            Via Email:{" "}
            <a
              href="/cdn-cgi/l/email-protection"
              className="__cf_email__"
              data-cfemail="1165787663747f70657e633f70616151767c70787d3f727e7c"
            >
              [email&#160;protected]
            </a>
          </li>
          <li>Via this Link: help.tigrenator.com</li>
        </ul>
      </div>
    </div>
  );
};

export default Disclaimer;
