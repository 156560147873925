import { FC } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import styles from "./styles.module.scss";
import { ICategoryProperty } from "../../../../models";
import useCalculator from "../../../hooks/useCalculator";

interface ICategoryCard {
  item: ICategoryProperty;
}

const CategoryCard: FC<ICategoryCard> = ({ item }) => {
  const { category, setCategory, setSubcategoryID } = useCalculator();
  const { id, name, icon } = item;

  const onClick = () => {
    setCategory(id);
    setSubcategoryID("");
  };

  return (
    <div
      className={`${styles.card} ${category === id && styles.active}`}
      onClick={onClick}
    >
      <div className={styles.cont}>
        <KTSVG
          path={icon}
          className={category === id ? styles.iconActive : styles.icon}
        />
        <div className={`${styles.name} poppins`}>{name}</div>
      </div>
    </div>
  );
};

export default CategoryCard;
