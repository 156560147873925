import { ReactNode, Dispatch, SetStateAction } from "react";

// Category
export enum ECategory {
  artist = "artist",
  marketeer = "marketeer",
  creator = "creator",
  designer = "designer",
  developer = "developer",
  administrative = "administrative",
  taskHunter = "taskHunter",
}

export interface ISubcategory {
  id: string;
  name: string;
  category: string;
  baseSalary: number | string;
  internSalary: number | string;
  partTime: number | string;
  hour: number | string;
  freelancerHour?: number | string;
  es: string;
  en: string;
}

export interface IDescription {
  en: string;
  es: string;
}

export interface ISubCategory {
  id: string;
  name: string;
  salary: number;
  description: IDescription;
}

export interface ICategoryProperty {
  id: string;
  name: string;
  icon: string;
  description: IDescription;
  subcategories: ISubCategory[];
}

export interface ICategory {
  artist?: ICategoryProperty;
  marketeer?: ICategoryProperty;
  creator?: ICategoryProperty;
  designer?: ICategoryProperty;
  developer?: ICategoryProperty;
  administrative?: ICategoryProperty;
  taskHunter?: ICategoryProperty;
}

export const Category = {
  artist: ECategory.artist,
  marketeer: ECategory.marketeer,
  creator: ECategory.creator,
  designer: ECategory.designer,
  developer: ECategory.developer,
  administrative: ECategory.administrative,
  taskHunter: ECategory.taskHunter,
};

export interface ICtxComponent {
  children: ReactNode;
}

export interface IFirebaseContext {
  app: any;
  auth: any;
  db: any;
  analytics: any;

  // States
  categories: ICategoryProperty[];
  setCategories: Dispatch<SetStateAction<ICategoryProperty[]>>;

  subcategories: ISubcategory[];
  setSubcategories: Dispatch<SetStateAction<ISubcategory[]>>;

  lifeCost: ILifeCost[];
  setLifeCost: Dispatch<SetStateAction<ILifeCost[]>>;

  tasks: ITask[];
  setTasks: Dispatch<SetStateAction<ITask[]>>;

  authLoading: boolean;
  setAuthLoading: Dispatch<SetStateAction<boolean>>;
}

export enum ECalculatorType {
  project = "project",
  hour = "hour",
  salary = "salary",
}

export interface ICalculatorContext {
  calculatorType: ECalculatorType;
  setCalculatorType: Dispatch<SetStateAction<ECalculatorType>>;
  category: ECategory;
  setCategory: any;

  subcategoryID: string;
  setSubcategoryID: any;
  projectResult: ITask[];
  setProjectResult: Dispatch<SetStateAction<ITask[]>>;
  currentTag: ITask | undefined;
  setCurrentTag: Dispatch<SetStateAction<ITask | undefined>>;
}

export interface ITask {
  category: string;
  subcategoryId: string;
  name: string;
  en: string;
  es: string;
  hours: number | string;
  level: string;
  tariff: number | string;
}

export enum ETalentType {
  bots = "bots",
  human = "human",
}

export interface ITalentContext {
  talentType: ETalentType;
  setTalentType: Dispatch<SetStateAction<ETalentType>>;
}

export interface ILifeCost {
  country: string;
  month: number;
  rent: number;
  total: number;
  totalHours: number | string;
}

export interface IAdditional {
  id: string;
  name: string;
  checked: boolean;
  value: number;
}

export interface IFilterProp {
  value: number;
  action: string;
}

export interface IFilterValues {
  client: IFilterProp;
  experience: IFilterProp;
  priority: IFilterProp;
}

export interface IInit {
  categories: ICategoryProperty[];
  subcategories: ISubcategory;
}
