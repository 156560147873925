import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { ICategoryProperty, ILifeCost, ISubcategory } from "../models";
import { TOTAL_HOURS_IN_MONTH, filterValues } from "./constants";

export const getPercent = (value: number, percentage: number): number => {
  return Math.floor((value * percentage) / 100);
};

export const operation = (op: string, a: number, b: number) => {
  if (["sum", "equ"].includes(op)) {
    return a + b;
  }

  if (["min"].includes(op)) {
    return a - b;
  }

  return a;
};

export const actionWithPercent = (tariff: number, filter: any) => {
  let finalTariff = tariff;

  for (let prop in filter) {
    const action = filter[prop];
    let currentProp: any = filterValues[prop];
    let currentAction: any = currentProp[action];
    let percent = 0;

    if (prop === "client") {
      percent = percentage(tariff, currentAction.value);
    }

    if (["experience", "priority"].includes(prop)) {
      percent = percentage(finalTariff, currentAction.value);
    }

    if (["availability"].includes(prop)) {
      percent = percentage(finalTariff, currentAction.value);
    }

    finalTariff = operation(currentAction.action, finalTariff, percent);
  }

  return finalTariff;
};

export const getValueWithPercent = (salary: number, filter: any) => {
  let finalSalary = salary;
  const finalObj: any = {
    client: {
      value: 0,
      action: null,
    },
    experience: {
      value: 0,
      action: null,
    },
    priority: {
      value: 0,
      action: null,
    },
    availability: {
      value: 0,
      action: null,
    },
  };

  for (let prop in filter) {
    const action = filter[prop];
    let currentProp: any = filterValues[prop];
    let currentAction: any = currentProp[action];

    if (prop === "client") {
      finalObj.client.value = percentage(salary, currentAction.value);
      finalObj.client.action = currentAction.action;
    }

    if (["experience", "priority"].includes(prop)) {
      finalObj[prop].value = percentage(finalSalary, currentAction.value);
      finalObj[prop].action = currentAction.action;
    }

    if (["availability"].includes(prop)) {
      finalObj.availability.value = percentage(
        finalSalary,
        currentAction.value
      );
      finalObj.availability.action = currentAction.action;
    }
  }

  return finalObj;
};

export const getSalaryHours = (value: number): string =>
  (value / TOTAL_HOURS_IN_MONTH).toFixed(1);

export const getSalaryHoursNum = (value: number): number =>
  value / TOTAL_HOURS_IN_MONTH;

export const fmtCategories = (
  snap: QuerySnapshot<DocumentData>
): ICategoryProperty[] => {
  const docList: DocumentData[] = [];
  snap.forEach((doc) => docList.push(doc.data()));
  const result: ICategoryProperty[] = docList.map(
    ({ id, name, icon, description, subcategories }) => {
      return {
        id,
        name,
        icon,
        description,
        subcategories,
      };
    }
  );
  return result;
};

export const fmtSubcategories = (
  snap: QuerySnapshot<DocumentData>
): ISubcategory[] => {
  const docList: DocumentData[] = [];
  snap.forEach((doc) => docList.push(doc.data()));
  const result: ISubcategory[] = docList.map(
    ({
      id,
      name,
      category,
      internSalary,
      partTime,
      hour,
      freelancerHour,
      baseSalary,
      es,
      en,
    }) => {
      return {
        id,
        name,
        category,
        baseSalary,
        internSalary,
        partTime,
        hour,
        freelancerHour,
        es,
        en,
      };
    }
  );
  return result;
};

export const fmtTasks = (
  snap: QuerySnapshot<DocumentData>
): ICategoryProperty[] => {
  const docList: DocumentData[] = [];
  snap.forEach((doc) => docList.push(doc.data()));
  const result: any[] = docList.map(
    ({ subcategoryId, name, en, es, hours, level }) => {
      return {
        subcategoryId,
        name,
        en,
        es,
        hours,
        level,
      };
    }
  );
  return result;
};

export const fmtLifeCost = (snap: QuerySnapshot<DocumentData>): ILifeCost[] => {
  const docList: DocumentData[] = [];
  snap.forEach((doc) => docList.push(doc.data()));
  const result: ILifeCost[] = docList.map(
    ({ country, month, rent, total, totalHours }) => {
      return {
        country,
        month,
        rent,
        total,
        totalHours,
      };
    }
  );

  result.sort((a, b) => a.country.localeCompare(b.country));
  return result;
};

export const fmtData = (snap: any) => {
  const result: any[] = snap.map(
    ({
      id,
      category,
      name,
      en,
      es,
      baseSalary,
      hour,
      partTime,
      internSalary,
      freelanceHour,
    }: any) => {
      return {
        id,
        category,
        name,
        en,
        es,
        baseSalary,
        hour,
        partTime,
        internSalary,
        freelanceHour,
      };
    }
  );
  return result;
};

export const searchByCategoryName = (
  list: ICategoryProperty[],
  categoryName: string
): ICategoryProperty | undefined => {
  const result = list.find((cat) => cat.id === categoryName);
  return result;
};

const verifyName = (value: any, first: boolean = true) => {
  const isString = typeof value === "string";
  const isInvalid = [null, undefined, "", false].includes(value);
  if (isInvalid || !isString) return "";
  const splitted = value.split(" ");
  return first ? splitted[0] : splitted[1];
};

export const fmtUser = (user: any) => {
  const auth = {
    api_token: user.uid,
    refreshToken: user.refreshToken,
  };

  const newUser = {
    id: `${user.uid}`,
    username: `${user.email}`,
    email: `${user.email}`,
    first_name: `${verifyName(user?.displayName)}`,
    last_name: `${verifyName(user?.displayName, false)}`,
    fullname: ![null, undefined, ""].includes(user?.displayName)
      ? user?.displayName
      : "",
    phone: ![null, undefined, ""].includes(user?.phoneNumber)
      ? user?.phoneNumber
      : "",
    pic: ![null, undefined, ""].includes(user?.photoURL) ? user?.photoURL : "",
    language: "es",
    auth,
  };

  return newUser;
};

export const percentage = (value: number, percent: number) =>
  (percent / 100) * value;

export const lifeCostSort = (lifeCostList: ILifeCost[]) => {
  lifeCostList.sort((a, b) => a.country.localeCompare(b.country));
  return lifeCostList;
};

export const debounce = (func: any, wait: number) => {
  let timeout: any;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func();
    }, wait);
  };
};

export const filterByCategory = (salary: number, filter: any) => {};
