import { IFilterValues } from "@models/";

export const TOTAL_HOURS_IN_MONTH = 160;

export const variants: any = {
  project: {
    title: "Calculadora por proyecto",
    text: `
      Cobrar por proyecto es una opción mejor para los trabajadores
      autónomos debido al hecho de que les permite cobrar una tarifa
      predeterminada por completar un proyecto. Esto significa que el
      trabajador recibirá su pago a medida que se complete el proyecto,
      lo que significa que no tendrá que trabajar el mismo número de
      horas cada mes para ganar el mismo salario. Además, esto también
      le da al cliente una idea clara de cuánto costará el trabajo
      exactamente.
    `,
  },
  hour: {
    title: "Calculadora por hora",
    text: `
      Es mejor cobrar por trabajo freelance por hora cuando se trata de un
      proyecto pequeño y relativamente simple, ya que así se puede acordar un
      precio basado en el tiempo que tomará completar el trabajo. Sin embargo,
      cuando se trata de proyectos más grandes y complejos, es mejor cobrar por
      proyecto, ya que le da al cliente una idea clara de cuánto le costará el
      proyecto completo.
    `,
  },
  salary: {
    title: "Calculadora por sueldo mensual",
    text: `
      Cuando se trata de trabajos en los que el trabajador se compromete a
      trabajar una cantidad fija de horas por mes, es mejor cobrar un sueldo
      fijo. Esto le da al trabajador una idea clara de cuánto dinero ganará cada
      mes, sin tener que preocuparse por los trabajos adicionales que pueda
      llegar a tener. Además, esto también le da al cliente una idea clara de
      cuánto costará el trabajo a largo plazo.
    `,
  },
  bots: {
    title: "Robots",
    text: `
      SIentete libre de colaborar con la victoria de las maquinas, a
      continuación una creciente lista de herramientas inteligentes capaces
      de hacer casi todo, si prefieres confiar en humanos revisa nuestra
      comunidad de freelancers.
    `,
  },
  human: {
    title: "Humanos",
    text: `
      Las tareas de confianza más importantes de tu proyecto deberían estar
      en manos de profesionales y apasionados, si te da igual tenemos una
      sección de bots para realizar tareas mecánicas.
    `,
  },
};

export const additional = [
  {
    id: "vacations",
    name: "Vacaciones pagas (1 día extra pago por mes)",
    checked: false,
    value: 2.5,
  },
  {
    id: "health",
    name: "Bono alimentos y salud (+10%) +/-",
    checked: false,
    value: 10,
  },
  {
    id: "studies",
    name: "Bono Estudios y ocio (+5%) +/-",
    checked: false,
    value: 5,
  },
  {
    id: "equipment",
    name: "Bono equipos",
    checked: false,
    value: 3,
  },
  {
    id: "exclusive",
    name: "Pago extra por exclusividad (10%) + -",
    checked: false,
    value: 10,
  },
];

export const filterValues: IFilterValues = {
  client: {
    particular: {
      value: 20,
      action: "min",
    },
    startup: {
      value: 0,
      action: "equ",
    },
    company: {
      value: 50,
      action: "sum",
    },
  },
  experience: {
    noob: {
      value: 50,
      action: "min",
    },
    junior: {
      value: 0,
      action: "equ",
    },
    senior: {
      value: 50,
      action: "sum",
    },
    expert: {
      value: 100,
      action: "sum",
    },
  },
  priority: {
    low: {
      value: 15,
      action: "min",
    },
    mid: {
      value: 0,
      action: "equ",
    },
    high: {
      value: 50,
      action: "sum",
    },
  },
  availability: {
    full: {
      value: 0,
      action: "equ",
    },
    part: {
      value: 48,
      action: "min",
    },
    intern: {
      value: 70,
      action: "min",
    },
  },
};
