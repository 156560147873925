export const robots = [
  {
    category: "designer",
    name: "Adobe Photoshop",
    money: "De pago",
    es: "Potente software de edición y creación de imágenes.",
    link: "https://www.adobe.com/products/photoshop.html",
    en: "",
  },
  {
    category: "designer",
    name: "Sketch",
    money: "Freemium",
    es: "Herramienta de diseño vectorial para Mac.",
    link: "https://www.sketch.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Figma",
    money: "Freemium",
    es: "Herramienta de diseño colaborativa en la nube.",
    link: "https://www.figma.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Canva",
    money: "Freemium",
    es: "Plataforma de diseño gráfico en línea con plantillas personalizables.",
    link: "https://www.canva.com/",
    en: "",
  },
  {
    category: "designer",
    name: "InVision Studio",
    money: "Gratuita",
    es: "Software de diseño de interfaces y prototipado.",
    link: "https://www.invisionapp.com/studio",
    en: "",
  },
  {
    category: "designer",
    name: "Gravit Designer",
    money: "Gratuita",
    es: "Software de diseño vectorial para Windows, Mac, Linux y en línea.",
    link: "https://www.designer.io/en/",
    en: "",
  },
  {
    category: "designer",
    name: "Affinity Designer",
    money: "De pago",
    es: "Software de diseño vectorial y de ilustración.",
    link: "https://affinity.serif.com/en-gb/designer/",
    en: "",
  },
  {
    category: "designer",
    name: "CorelDRAW",
    money: "De pago",
    es: "Software de diseño gráfico vectorial.",
    link: "https://www.coreldraw.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Vectr",
    money: "Gratuita",
    es: "Software de diseño vectorial multiplataforma.",
    link: "https://vectr.com/",
    en: "",
  },
  {
    category: "designer",
    name: "GIMP",
    money: "Gratuita",
    es: "Software de edición de imágenes y diseño gráfico.",
    link: "https://www.gimp.org/",
    en: "",
  },
  {
    category: "designer",
    name: "Blender",
    money: "Gratuita",
    es: "Software de modelado, animación y renderizado 3D.",
    link: "https://www.blender.org/",
    en: "",
  },
  {
    category: "designer",
    name: "Krita",
    money: "Gratuita",
    es: "Software de dibujo y pintura digital.",
    link: "https://krita.org/en/",
    en: "",
  },
  {
    category: "designer",
    name: "Paint Tool SAI",
    money: "De pago",
    es: "Software de dibujo y pintura digital.",
    link: "https://www.systemax.jp/en/sai/",
    en: "",
  },
  {
    category: "designer",
    name: "Procreate",
    money: "De pago",
    es: "Software de dibujo y pintura digital para iPad.",
    link: "https://procreate.art/",
    en: "",
  },
  {
    category: "designer",
    name: "Clip Studio Paint",
    money: "Freemium",
    es: "Software de dibujo y pintura digital para manga y cómic.",
    link: "https://www.clipstudio.net/en",
    en: "",
  },
  {
    category: "designer",
    name: "Medibang Paint",
    money: "Gratuita",
    es: "Software de dibujo y pintura digital para manga y cómic.",
    link: "https://medibangpaint.com/en/",
    en: "",
  },
  {
    category: "designer",
    name: "Adobe Illustrator",
    money: "De pago",
    es: "Software de diseño gráfico vectorial.",
    link: "https://www.adobe.com/products/illustrator.html",
    en: "",
  },
  {
    category: "designer",
    name: "AutoCAD",
    money: "De pago",
    es: "Software de diseño asistido por computadora (CAD) para dibujo técnico.",
    link: "https://www.autodesk.com/products/autocad/overview",
    en: "",
  },
  {
    category: "designer",
    name: "SolidWorks",
    money: "De pago",
    es: "Software de diseño y modelado en 3D para ingeniería mecánica.",
    link: "https://www.solidworks.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Tinkercad",
    money: "Gratuita",
    es: "Software de modelado en 3D para principiantes.",
    link: "https://www.tinkercad.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Fusion 360",
    money: "Freemium",
    es: "Software de diseño y modelado en 3D para ingeniería mecánica y diseño industrial.",
    link: "https://www.autodesk.com/products/fusion-360/overview",
    en: "",
  },
  {
    category: "designer",
    name: "Onshape",
    money: "Freemium",
    es: "Software de diseño y modelado en 3D en la nube.",
    link: "https://www.onshape.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Rhino 3D",
    money: "De pago",
    es: "Software de modelado en 3D para diseño industrial y arquitectónico.",
    link: "https://www.rhino3d.com/",
    en: "",
  },
  {
    category: "designer",
    name: "3ds Max",
    money: "De pago",
    es: "Software de modelado, animación y renderizado en 3D.",
    link: "https://www.autodesk.com/products/3ds-max/overview",
    en: "",
  },
  {
    category: "designer",
    name: "Unity",
    money: "Freemium",
    es: "Motor de videojuegos y herramienta de diseño de experiencias interactivas en 2D y 3D.",
    link: "https://unity.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Unreal Engine",
    money: "Gratuita",
    es: "Motor de videojuegos y herramienta de diseño de experiencias interactivas en 2D y 3D.",
    link: "https://www.unrealengine.com/",
    en: "",
  },
  {
    category: "designer",
    name: "C4D",
    money: "De pago",
    es: "Software de modelado, animación y renderizado en 3D para diseño gráfico y visualización.",
    link: "https://www.maxon.net/cinema-4d",
    en: "",
  },
  {
    category: "designer",
    name: "Substance Designer",
    money: "Freemium",
    es: "Software de diseño de texturas y materiales para 3D.",
    link: "https://www.substance3d.com/products/substance-designer",
    en: "",
  },
  {
    category: "designer",
    name: "Marvelous Designer",
    money: "De pago",
    es: "Software de diseño de ropa y telas en 3D.",
    link: "https://www.marvelousdesigner.com/",
    en: "",
  },
  {
    category: "designer",
    name: "ZBrush",
    money: "De pago",
    es: "Software de escultura digital en 3D.",
    link: "https://pixologic.com/zbrush/",
    en: "",
  },
  {
    category: "designer",
    name: "Autodesk SketchBook",
    money: "Freemium",
    es: "Software de dibujo y pintura digital en 2D.",
    link: "https://sketchbook.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Paint.net",
    money: "Gratuita",
    es: "Software de edición de imágenes y diseño gráfico para Windows.",
    link: "https://www.getpaint.net/",
    en: "",
  },
  {
    category: "designer",
    name: "Photopea",
    money: "Gratuita",
    es: "Editor de fotos en línea con funciones similares a Photoshop.",
    link: "https://www.photopea.com/",
    en: "",
  },
  {
    category: "designer",
    name: "LibreOffice Draw",
    money: "Gratuita",
    es: "Software de dibujo y diagramación vectorial en 2D.",
    link: "https://www.libreoffice.org/discover/draw/",
    en: "",
  },
  {
    category: "designer",
    name: "Google Drawings",
    money: "Gratuita",
    es: "Herramienta de dibujo y diagramación en línea integrada en Google Drive.",
    link: "https://www.google.com/intl/en-GB_ALL/drive/apps.html",
    en: "",
  },
  {
    category: "designer",
    name: "Gravit Engine",
    money: "Gratuita",
    es: "Biblioteca de componentes de diseño para la creación de interfaces.",
    link: "https://www.designer.io/en/gravit",
    en: "",
  },
  {
    category: "designer",
    name: "Vecteezy Editor",
    money: "Gratuita",
    es: "Editor de vectores en línea.",
    link: "https://www.vecteezy.com/editor/",
    en: "",
  },
  {
    category: "designer",
    name: "Vectary",
    money: "Freemium",
    es: "Software de diseño en 3D en línea.",
    link: "https://www.vectary.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Vecteezy Pro",
    money: "De pago",
    es: "Biblioteca de vectores, imágenes y plantillas de diseño.",
    link: "https://www.vecteezy.com/pro",
    en: "",
  },
  {
    category: "designer",
    name: "Easel.ly",
    money: "Freemium",
    es: "Herramienta en línea para crear infografías.",
    link: "https://www.easel.ly/",
    en: "",
  },
  {
    category: "designer",
    name: "Lucidpress",
    money: "Freemium",
    es: "Software de diseño y publicación en línea.",
    link: "https://www.lucidpress.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Piktochart",
    money: "Freemium",
    es: "Herramienta en línea para crear infografías, presentaciones y pósters.",
    link: "https://piktochart.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Visme",
    money: "Freemium",
    es: "Software de diseño y visualización de datos en línea.",
    link: "https://www.visme.co/",
    en: "",
  },
  {
    category: "designer",
    name: "Venngage",
    money: "Freemium",
    es: "Herramienta en línea para crear infografías, presentaciones y pósters.",
    link: "https://venngage.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Snappa",
    money: "Freemium",
    es: "Software de diseño gráfico en línea con plantillas personalizables.",
    link: "https://snappa.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Crello",
    money: "Freemium",
    es: "Herramienta de diseño gráfico en línea con plantillas personalizables.",
    link: "https://crello.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Design Wizard",
    money: "Freemium",
    es: "Software de diseño gráfico en línea con plantillas personalizables.",
    link: "https://www.designwizard.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Desygner",
    money: "Freemium",
    es: "Software de diseño gráfico en línea con plantillas personalizables.",
    link: "https://desygner.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Infogram",
    money: "Freemium",
    es: "Software de visualización de datos en línea.",
    link: "https://infogram.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Adobe XD",
    money: "Freemium",
    es: "Software de diseño de interfaces y prototipado en 2D y 3D.",
    link: "https://www.adobe.com/products/xd.html",
    en: "",
  },
  {
    category: "designer",
    name: "RunwayML",
    money: "De pago",
    es: "Plataforma de IA para artistas y diseñadores, que permite crear y explorar modelos de aprendizaje profundo.",
    link: "https://runwayml.com/",
    en: "",
  },
  {
    category: "designer",
    name: "DeepArt",
    money: "Freemium",
    es: "Herramienta que utiliza algoritmos de IA para aplicar estilos artísticos a tus imágenes.",
    link: "https://deepart.io/",
    en: "",
  },
  {
    category: "designer",
    name: "NeuralStyler",
    money: "De pago",
    es: "Software que utiliza redes neuronales para aplicar estilos artísticos a tus imágenes.",
    link: "https://neuralstyler.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Pikazo",
    money: "De pago",
    es: "Aplicación que utiliza redes neuronales para convertir tus fotos en obras de arte.",
    link: "https://www.pikazoapp.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Artisto",
    money: "Freemium",
    es: "Herramienta que utiliza IA para aplicar estilos artísticos a tus fotos y videos.",
    link: "https://artisto.net/",
    en: "",
  },
  {
    category: "designer",
    name: "Prisma",
    money: "Freemium",
    es: "Aplicación que utiliza IA para aplicar estilos artísticos a tus fotos y videos en tiempo real.",
    link: "https://prisma-ai.com/",
    en: "",
  },
  {
    category: "designer",
    name: "DeepDream",
    money: "Gratuita",
    es: "Herramienta que utiliza IA para generar imágenes psicodélicas a partir de tus fotos.",
    link: "https://deepdreamgenerator.com/",
    en: "",
  },
  {
    category: "designer",
    name: "NeuralDraw",
    money: "De pago",
    es: "Software que utiliza IA para convertir tus bocetos en ilustraciones vectoriales.",
    link: "https://neuraldraw.com/",
    en: "",
  },
  {
    category: "designer",
    name: "AI Painter",
    money: "Gratuita",
    es: "Herramienta en línea que utiliza IA para convertir tus fotos en obras de arte.",
    link: "https://www.ai-art.tokyo/en/",
    en: "",
  },
  {
    category: "designer",
    name: "PaintsChainer",
    money: "Gratuita",
    es: "Herramienta en línea que utiliza IA para colorear tus dibujos en blanco y negro.",
    link: "https://paintschainer.preferred.tech/index_en.html",
    en: "",
  },
  {
    category: "designer",
    name: "DALL-E",
    money: "Gratuita",
    es: "Herramienta de OpenAI que utiliza IA para generar imágenes surrealistas a partir de descripciones de texto.",
    link: "https://openai.com/dall-e/",
    en: "",
  },
  {
    category: "designer",
    name: "Artbreeder",
    money: "Freemium",
    es: "Herramienta que utiliza IA para generar imágenes a partir de combinaciones de estilos y características.",
    link: "https://www.artbreeder.com/",
    en: "",
  },
  {
    category: "designer",
    name: "NeuralTools",
    money: "De pago",
    es: "Software que utiliza IA para mejorar la calidad y resolución de tus imágenes.",
    link: "https://www.neuraltools.ai/",
    en: "",
  },
  {
    category: "designer",
    name: "NeuralStyle.art",
    money: "De pago",
    es: "Aplicación que utiliza IA para aplicar estilos artísticos a tus fotos y videos.",
    link: "https://neuralstyle.art/",
    en: "",
  },
  {
    category: "designer",
    name: "DeepPainter",
    money: "De pago",
    es: "Software que utiliza IA para convertir tus fotos en pinturas al óleo.",
    link: "https://deeppainter.ai/",
    en: "",
  },
  {
    category: "designer",
    name: "PaintsTransfer",
    money: "De pago",
    es: "Herramienta que utiliza IA para transferir estilos artísticos a tus fotos.",
    link: "https://www.paintstransfer.com/",
    en: "",
  },
  {
    category: "designer",
    name: "ImagePlayground",
    money: "De pago",
    es: "Herramienta que utiliza IA para mejorar la calidad y estilos de tus imágenes.",
    link: "https://www.imageplayground.com/",
    en: "",
  },
  {
    category: "designer",
    name: "ColorizeBot",
    money: "De pago",
    es: "Herramienta que utiliza IA para colorear tus fotos en blanco y negro.",
    link: "https://colorizebot.com/",
    en: "",
  },
  {
    category: "designer",
    name: "DeepArt Effects",
    money: "Freemium",
    es: "Aplicación que utiliza IA para aplicar efectos artísticos a tus fotos.",
    link: "https://www.deeparteffects.com/",
    en: "",
  },
  {
    category: "designer",
    name: "AI Gahaku",
    money: "Gratuita",
    es: "Herramienta que utiliza IA para convertir tus fotos en retratos estilo anime.",
    link: "https://nagadomi.github.io/AI-art-tts-demo/ai_gahaku.html",
    en: "",
  },
  {
    category: "designer",
    name: "Let's Enhance",
    money: "Freemium",
    es: "Herramienta que utiliza IA para mejorar la calidad y resolución de tus imágenes.",
    link: "https://letsenhance.io/",
    en: "",
  },
  {
    category: "designer",
    name: "NeuralMix",
    money: "De pago",
    es: "Software que utiliza IA para separar y mezclar componentes de tus imágenes.",
    link: "https://neuralmix.ai/",
    en: "",
  },
  {
    category: "designer",
    name: "NeuralTools Pro",
    money: "De pago",
    es: "Software que utiliza IA para mejorar la calidad y resolución de tus imágenes.",
    link: "https://neuraltoolspro.com/",
    en: "",
  },
  {
    category: "designer",
    name: "Waifu2x",
    money: "Gratuita",
    es: "Herramienta en línea que utiliza IA para mejorar la calidad y resolución de tus imágenes.",
    link: "http://waifu2x.udp.jp/",
    en: "",
  },
  {
    category: "designer",
    name: "NeuralStyleHub",
    money: "Gratuita",
    es: "Herramienta que utiliza IA para aplicar estilos artísticos a tus fotos y videos.",
    link: "https://github.com/ProGamerGov/neural-style-hub",
    en: "",
  },
  {
    category: "creator",
    name: "Adobe Premiere Pro",
    money: "De pago",
    es: "Editor de video profesional.",
    link: "https://www.adobe.com/products/premiere.html",
    en: "",
  },
  {
    category: "creator",
    name: "Final Cut Pro",
    money: "De pago",
    es: "Editor de video profesional para Mac.",
    link: "https://www.apple.com/final-cut-pro/",
    en: "",
  },
  {
    category: "creator",
    name: "iMovie",
    money: "Gratuita",
    es: "Editor de video para Mac e iOS.",
    link: "https://www.apple.com/imovie/",
    en: "",
  },
  {
    category: "creator",
    name: "DaVinci Resolve",
    money: "Gratuita/De pago",
    es: "Editor de video con funciones avanzadas de corrección de color.",
    link: "https://www.blackmagicdesign.com/products/davinciresolve/",
    en: "",
  },
  {
    category: "creator",
    name: "Filmora",
    money: "Freemium",
    es: "Editor de video con funciones de efectos especiales.",
    link: "https://filmora.wondershare.com/",
    en: "",
  },
  {
    category: "creator",
    name: "OpenShot",
    money: "Gratuita",
    es: "Editor de video de código abierto.",
    link: "https://www.openshot.org/",
    en: "",
  },
  {
    category: "creator",
    name: "VSDC Free Video Editor",
    money: "Gratuita",
    es: "Editor de video con herramientas avanzadas de edición y efectos especiales.",
    link: "https://www.videosoftdev.com/free-video-editor/",
    en: "",
  },
  {
    category: "creator",
    name: "Lightworks",
    money: "Gratuita/De pago",
    es: "Editor de video utilizado en películas y programas de televisión.",
    link: "https://www.lwks.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Blender",
    money: "Gratuita",
    es: "Software de animación y modelado en 3D con funciones de edición de video.",
    link: "https://www.blender.org/",
    en: "",
  },
  {
    category: "creator",
    name: "HitFilm Express",
    money: "Gratuita",
    es: "Editor de video con herramientas avanzadas de efectos especiales.",
    link: "https://fxhome.com/hitfilm-express",
    en: "",
  },
  {
    category: "creator",
    name: "Shotcut",
    money: "Gratuita",
    es: "Editor de video de código abierto.",
    link: "https://shotcut.org/",
    en: "",
  },
  {
    category: "creator",
    name: "KineMaster",
    money: "Freemium",
    es: "Editor de video para dispositivos móviles.",
    link: "https://www.kinemaster.com/",
    en: "",
  },
  {
    category: "creator",
    name: "PowerDirector",
    money: "Freemium",
    es: "Editor de video para dispositivos móviles.",
    link: "https://www.cyberlink.com/products/powerdirector-video-editing-software/features.html",
    en: "",
  },
  {
    category: "creator",
    name: "LumaFusion",
    money: "De pago",
    es: "Editor de video profesional para dispositivos móviles.",
    link: "https://luma-touch.com/lumafusion-for-ios/",
    en: "",
  },
  {
    category: "creator",
    name: "Magisto",
    money: "Freemium",
    es: "Editor de video en línea con herramientas de IA para crear videos automáticamente.",
    link: "https://www.magisto.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Animoto",
    money: "Freemium",
    es: "Herramienta en línea para crear videos con plantillas personalizables.",
    link: "https://animoto.com/",
    en: "",
  },
  {
    category: "creator",
    name: "WeVideo",
    money: "Freemium",
    es: "Editor de video en línea con herramientas de colaboración.",
    link: "https://www.wevideo.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Clipchamp",
    money: "Freemium",
    es: "Editor de video en línea con herramientas de compresión y conversión.",
    link: "https://clipchamp.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Kapwing",
    money: "Freemium",
    es: "Herramienta en línea para crear videos, GIF y memes.",
    link: "https://www.kapwing.com/",
    en: "",
  },
  {
    category: "creator",
    name: "InVideo",
    money: "Freemium",
    es: "Editor de video en línea con plantillas personalizables y herramientas de IA.",
    link: "https://invideo.io/",
    en: "",
  },
  {
    category: "creator",
    name: "FilmForth",
    money: "Gratuita",
    es: "Editor de video para Windows con herramientas de edición básicas.",
    link: "https://filmforth.com/",
    en: "",
  },
  {
    category: "creator",
    name: "VideoPad Video Editor",
    money: "De pago",
    es: "Editor de video para Windows y Mac con funciones avanzadas de edición.",
    link: "https://www.nchsoftware.com/videopad/index.html",
    en: "",
  },
  {
    category: "creator",
    name: "Corel VideoStudio",
    money: "De pago",
    es: "Editor de video para Windows con funciones avanzadas de edición y efectos especiales.",
    link: "https://www.videostudiopro.com/",
    en: "",
  },
  {
    category: "creator",
    name: "CyberLink PowerDirector",
    money: "De pago",
    es: "Editor de video para Windows con herramientas avanzadas de edición y efectos especiales.",
    link: "https://www.cyberlink.com/products/powerdirector-video-editing-software/features.html",
    en: "",
  },
  {
    category: "creator",
    name: "Pinnacle Studio",
    money: "De pago",
    es: "Editor de video para Windows con funciones avanzadas de edición y efectos especiales.",
    link: "https://www.pinnaclesys.com/en/products/studio/",
    en: "",
  },
  {
    category: "creator",
    name: "Nero Video",
    money: "De pago",
    es: "Editor de video para Windows con herramientas avanzadas de edición y efectos especiales.",
    link: "https://www.nero.com/enu/products/nero-video/",
    en: "",
  },
  {
    category: "creator",
    name: "Movavi Video Editor",
    money: "De pago",
    es: "Editor de video para Windows y Mac con herramientas de edición básicas.",
    link: "https://www.movavi.com/videoeditor/",
    en: "",
  },
  {
    category: "creator",
    name: "Wondershare FilmoraPro",
    money: "De pago",
    es: "Editor de video para Windows y Mac con funciones avanzadas de edición y efectos especiales.",
    link: "https://filmora.wondershare.com/filmorapro-video-editor/",
    en: "",
  },
  {
    category: "creator",
    name: "Vegas Pro",
    money: "De pago",
    es: "Editor de video para Windows con herramientas avanzadas de edición y efectos especiales.",
    link: "https://www.vegascreativesoftware.com/us/vegas-pro/",
    en: "",
  },
  {
    category: "creator",
    name: "Avid Media Composer",
    money: "De pago",
    es: "Editor de video utilizado en la industria cinematográfica y televisiva.",
    link: "https://www.avid.com/media-composer",
    en: "",
  },
  {
    category: "creator",
    name: "Lumen5",
    money: "Freemium",
    es: "Herramienta en línea que utiliza IA para convertir texto en videos.",
    link: "https://lumen5.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Magisto",
    money: "Freemium",
    es: "Editor de video en línea con herramientas de IA para crear videos automáticamente.",
    link: "https://www.magisto.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Rocketium",
    money: "Freemium",
    es: "Herramienta en línea que utiliza IA para crear videos y animaciones.",
    link: "https://rocketium.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Wibbitz",
    money: "De pago",
    es: "Plataforma en línea que utiliza IA para crear videos automáticamente.",
    link: "https://www.wibbitz.com/",
    en: "",
  },
  {
    category: "creator",
    name: "VidMob",
    money: "De pago",
    es: "Plataforma en línea que utiliza IA para la producción de videos publicitarios.",
    link: "https://www.vidmob.com/",
    en: "",
  },
  {
    category: "creator",
    name: "Vochi",
    money: "Freemium",
    es: "Aplicación móvil que utiliza IA para aplicar efectos especiales a tus videos.",
    link: "https://vochi.app/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Hootsuite",
    money: "Freemium",
    es: "Plataforma de gestión de redes sociales con herramientas de programación y análisis.",
    link: "https://hootsuite.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Buffer",
    money: "Freemium",
    es: "Plataforma de gestión de redes sociales con herramientas de programación y análisis.",
    link: "https://buffer.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "HubSpot",
    money: "Freemium",
    es: "Plataforma de marketing y ventas con herramientas de automatización y análisis.",
    link: "https://www.hubspot.es/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Mailchimp",
    money: "Freemium",
    es: "Plataforma de email marketing con herramientas de automatización y análisis.",
    link: "https://mailchimp.com/es/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Google Analytics",
    money: "Gratuita",
    es: "Plataforma de análisis de sitios web y aplicaciones.",
    link: "https://analytics.google.com/analytics/web/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Google Ads",
    money: "De pago",
    es: "Plataforma de publicidad en línea.",
    link: "https://ads.google.com/home/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Facebook Ads Manager",
    money: "De pago",
    es: "Plataforma de publicidad en Facebook e Instagram.",
    link: "https://www.facebook.com/business/help/1710077379203657",
    en: "",
  },
  {
    category: "marketeer",
    name: "Instagram",
    money: "Gratuita",
    es: "Red social para compartir fotos y videos.",
    link: "https://www.instagram.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Twitter",
    money: "Gratuita",
    es: "Red social para compartir mensajes cortos.",
    link: "https://twitter.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "LinkedIn",
    money: "Freemium",
    es: "Red social para profesionales y empresas.",
    link: "https://www.linkedin.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Canva",
    money: "Freemium",
    es: "Plataforma de diseño gráfico en línea con plantillas personalizables.",
    link: "https://www.canva.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Adobe Creative Cloud",
    money: "De pago",
    es: "Plataforma de diseño gráfico, edición de video y fotografía.",
    link: "https://www.adobe.com/creativecloud.html",
    en: "",
  },
  {
    category: "marketeer",
    name: "Trello",
    money: "Freemium",
    es: "Plataforma de gestión de proyectos con herramientas de organización y colaboración.",
    link: "https://trello.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Asana",
    money: "Freemium",
    es: "Plataforma de gestión de proyectos con herramientas de organización y colaboración.",
    link: "https://asana.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Monday.com",
    money: "Freemium",
    es: "Plataforma de gestión de proyectos con herramientas de organización y colaboración.",
    link: "https://monday.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "SocialGest",
    money: "Freemium",
    es: "Es un conjunto de herramientas para optimizar la ejecución de estrategias en redes sociales.",
    link: "https://www.socialgest.net",
    en: "",
  },
  {
    category: "marketeer",
    name: "Slack",
    money: "Freemium",
    es: "Plataforma de comunicación para equipos con herramientas de organización y colaboración.",
    link: "https://slack.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Zoom",
    money: "Freemium",
    es: "Plataforma de videoconferencia y comunicación en línea.",
    link: "https://zoom.us/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Google Meet",
    money: "Gratuita",
    es: "Plataforma de videoconferencia y comunicación en línea.",
    link: "https://meet.google.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Skype",
    money: "Gratuita",
    es: "Plataforma de comunicación en línea con herramientas de videoconferencia y mensajería.",
    link: "https://www.skype.com/es/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Discord",
    money: "Gratuita",
    es: "Plataforma de comunicación en línea para comunidades y equipos.",
    link: "https://discord.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "WordPress",
    money: "Gratuita",
    es: "Plataforma de creación y gestión de sitios web.",
    link: "https://wordpress.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Shopify",
    money: "De pago",
    es: "Plataforma de comercio electrónico para la creación y gestión de tiendas en línea.",
    link: "https://www.shopify.es/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Magento",
    money: "De pago",
    es: "Plataforma de comercio electrónico para la creación y gestión de tiendas en línea.",
    link: "https://magento.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "WooCommerce",
    money: "Gratuita",
    es: "Plugin de WordPress para la creación y gestión de tiendas en línea.",
    link: "https://woocommerce.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Yoast SEO",
    money: "Freemium",
    es: "Plugin de WordPress para la optimización de motores de búsqueda.",
    link: "https://yoast.com/wordpress/plugins/seo/",
    en: "",
  },
  {
    category: "marketeer",
    name: "SEMrush",
    money: "Freemium",
    es: "Plataforma de análisis y optimización de motores de búsqueda.",
    link: "https://es.semrush.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Ahrefs",
    money: "De pago",
    es: "Plataforma de análisis y optimización de motores de búsqueda.",
    link: "https://ahrefs.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Moz Pro",
    money: "Freemium",
    es: "Plataforma de análisis y optimización de motores de búsqueda.",
    link: "https://moz.com/products/pro/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Grammarly",
    money: "Freemium",
    es: "Herramienta en línea para la corrección gramatical y ortográfica.",
    link: "https://www.grammarly.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Hemingway Editor",
    money: "De pago",
    es: "Herramienta en línea para la mejora de la escritura.",
    link: "https://hemingwayapp.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Google Trends",
    money: "Gratuita",
    es: "Plataforma de análisis de tendencias en línea.",
    link: "https://trends.google.es/trends/",
    en: "",
  },
  {
    category: "marketeer",
    name: "BuzzSumo",
    money: "Freemium",
    es: "Plataforma de análisis de contenido en línea.",
    link: "https://buzzsumo.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Sumo",
    money: "Freemium",
    es: "Herramienta en línea para el crecimiento y optimización de sitios web.",
    link: "https://sumo.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Crazy Egg",
    money: "De pago",
    es: "Herramienta en línea para la optimización de sitios web.",
    link: "https://www.crazyegg.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Hotjar",
    money: "Freemium",
    es: "Herramienta en línea para la optimización de sitios web.",
    link: "https://www.hotjar.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Google Optimize",
    money: "Gratuita",
    es: "Plataforma de optimización de sitios web.",
    link: "https://optimize.google.com/optimize/home/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Persado",
    money: "De pago",
    es: "Plataforma de IA para la creación de mensajes de marketing personalizados.",
    link: "https://www.persado.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Albert",
    money: "De pago",
    es: "Plataforma de IA para la automatización de campañas publicitarias en línea.",
    link: "https://albert.ai/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Blueshift",
    money: "De pago",
    es: "Plataforma de IA para la personalización de campañas de marketing en línea.",
    link: "https://blueshift.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Boomtrain",
    money: "De pago",
    es: "Plataforma de IA para la personalización de mensajes de marketing en línea.",
    link: "https://boomtrain.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Conversica",
    money: "De pago",
    es: "Plataforma de IA para la automatización de conversaciones en línea con clientes potenciales.",
    link: "https://www.conversica.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Emarsys",
    money: "De pago",
    es: "Plataforma de IA para la automatización de campañas de email marketing.",
    link: "https://www.emarsys.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Exceed.ai",
    money: "Freemium",
    es: "Plataforma de IA para la automatización de conversaciones en línea con clientes potenciales.",
    link: "https://exceed.ai/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Hugging Face",
    money: "Gratuita",
    es: "Plataforma de IA para la creación de chatbots en línea.",
    link: "https://huggingface.co/",
    en: "",
  },
  {
    category: "marketeer",
    name: "IBM Watson Studio",
    money: "Freemium",
    es: "Plataforma de IA para el análisis y visualización de datos de marketing.",
    link: "https://www.ibm.com/cloud/watson-studio",
    en: "",
  },
  {
    category: "marketeer",
    name: "Lattice Engines",
    money: "De pago",
    es: "Plataforma de IA para la personalización de mensajes de marketing en línea.",
    link: "https://www.dunbradstreet.com/business-directory/company-profiles.lattice-engines.4ead4e83bdf8c0a0a6a1f6b9a2a0f6d7.html",
    en: "",
  },
  {
    category: "marketeer",
    name: "Marketo",
    money: "De pago",
    es: "Plataforma de IA para la automatización de campañas de marketing en línea.",
    link: "https://www.marketo.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Node",
    money: "De pago",
    es: "Plataforma de IA para la identificación de clientes potenciales en línea.",
    link: "https://www.node.io/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Optimizely",
    money: "De pago",
    es: "Plataforma de IA para la optimización de páginas web y campañas de marketing en línea.",
    link: "https://www.optimizely.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Persosa",
    money: "De pago",
    es: "Plataforma de IA para la personalización de mensajes de marketing en línea.",
    link: "https://persosa.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Phrasee",
    money: "De pago",
    es: "Plataforma de IA para la creación de mensajes de marketing en línea.",
    link: "https://phrasee.co/",
    en: "",
  },
  {
    category: "marketeer",
    name: "SalesForce Einstein",
    money: "De pago",
    es: "Plataforma de IA para la automatización de campañas de marketing en línea.",
    link: "https://www.salesforce.com/products/einstein/overview/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Siftrock",
    money: "De pago",
    es: "Plataforma de IA para la automatización de respuestas de email en línea.",
    link: "https://siftrock.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Smartling",
    money: "De pago",
    es: "Plataforma de IA para la traducción automática de contenido de marketing.",
    link: "https://www.smartling.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Sprinklr",
    money: "De pago",
    es: "Plataforma de IA para la gestión de redes sociales y campañas de marketing en línea.",
    link: "https://www.sprinklr.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Synthesia",
    money: "De pago",
    es: "Plataforma de IA para la creación de videos personalizados.",
    link: "https://www.synthesia.io/",
    en: "",
  },
  {
    category: "marketeer",
    name: "The Grid",
    money: "De pago",
    es: "Plataforma de IA para la creación de sitios web personalizados.",
    link: "https://thegrid.io/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Uberflip",
    money: "De pago",
    es: "Plataforma de IA para la personalización de contenido de marketing en línea.",
    link: "https://www.uberflip.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Unbounce",
    money: "Freemium",
    es: "Plataforma de IA para la creación de páginas de destino personalizadas.",
    link: "https://unbounce.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Unmetric",
    money: "De pago",
    es: "Plataforma de IA para el análisis de datos de redes sociales.",
    link: "https://unmetric.com/",
    en: "",
  },
  {
    category: "marketeer",
    name: "Zaius",
    money: "De pago",
    es: "Plataforma de IA para la automatización de campañas de marketing en línea.",
    link: "https://www.zaius.com/",
    en: "",
  },
  {
    category: "developer",
    name: "React",
    money: "Gratuita",
    es: "Librería de JavaScript para la creación de interfaces de usuario.",
    link: "https://reactjs.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Angular",
    money: "Gratuita",
    es: "Framework de JavaScript para la creación de aplicaciones web.",
    link: "https://angular.io/",
    en: "",
  },
  {
    category: "developer",
    name: "Vue.js",
    money: "Gratuita",
    es: "Framework de JavaScript para la creación de aplicaciones web.",
    link: "https://vuejs.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Node.js",
    money: "Gratuita",
    es: "Entorno de ejecución de JavaScript para la creación de aplicaciones web y servidores.",
    link: "https://nodejs.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Express",
    money: "Gratuita",
    es: "Framework de Node.js para la creación de servidores web y APIs.",
    link: "https://expressjs.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Django",
    money: "Gratuita",
    es: "Framework de Python para la creación de aplicaciones web.",
    link: "https://www.djangoproject.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Flask",
    money: "Gratuita",
    es: "Framework de Python para la creación de aplicaciones web y APIs.",
    link: "https://palletsprojects.com/p/flask/",
    en: "",
  },
  {
    category: "developer",
    name: "Ruby on Rails",
    money: "Gratuita",
    es: "Framework de Ruby para la creación de aplicaciones web.",
    link: "https://rubyonrails.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Laravel",
    money: "Gratuita",
    es: "Framework de PHP para la creación de aplicaciones web.",
    link: "https://laravel.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Bootstrap",
    money: "Gratuita",
    es: "Framework de CSS para la creación de interfaces de usuario.",
    link: "https://getbootstrap.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Materialize",
    money: "Gratuita",
    es: "Framework de CSS para la creación de interfaces de usuario.",
    link: "https://materializecss.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Foundation",
    money: "Gratuita",
    es: "Framework de CSS para la creación de interfaces de usuario.",
    link: "https://foundation.zurb.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Bulma",
    money: "Gratuita",
    es: "Framework de CSS para la creación de interfaces de usuario.",
    link: "https://bulma.io/",
    en: "",
  },
  {
    category: "developer",
    name: "Ionic",
    money: "Gratuita",
    es: "Framework de desarrollo de aplicaciones móviles híbridas con Angular y React.",
    link: "https://ionicframework.com/",
    en: "",
  },
  {
    category: "developer",
    name: "PhoneGap",
    money: "Gratuita",
    es: "Framework de desarrollo de aplicaciones móviles híbridas con HTML, CSS y JavaScript.",
    link: "https://phonegap.com/",
    en: "",
  },
  {
    category: "developer",
    name: "React Native",
    money: "Gratuita",
    es: "Framework de desarrollo de aplicaciones móviles híbridas con React.",
    link: "https://reactnative.dev/",
    en: "",
  },
  {
    category: "developer",
    name: "Xamarin",
    money: "De pago",
    es: "Framework de desarrollo de aplicaciones móviles híbridas con C# y .NET.",
    link: "https://dotnet.microsoft.com/apps/xamarin",
    en: "",
  },
  {
    category: "developer",
    name: "Flutter",
    money: "Gratuita",
    es: "Framework de desarrollo de aplicaciones móviles nativas con Dart.",
    link: "https://flutter.dev/",
    en: "",
  },
  {
    category: "developer",
    name: "NativeScript",
    money: "Gratuita",
    es: "Framework de desarrollo de aplicaciones móviles nativas con JavaScript y TypeScript.",
    link: "https://nativescript.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Apache Cordova",
    money: "Gratuita",
    es: "Framework de desarrollo de aplicaciones móviles híbridas con HTML, CSS y JavaScript.",
    link: "https://cordova.apache.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Expo",
    money: "Gratuita",
    es: "Plataforma para el desarrollo de aplicaciones móviles con React Native.",
    link: "https://expo.io/",
    en: "",
  },
  {
    category: "developer",
    name: "Figma",
    money: "Freemium",
    es: "Herramienta en línea para el diseño de interfaces de usuario con funciones de colaboración.",
    link: "https://www.figma.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Sketch",
    money: "De pago",
    es: "Herramienta de diseño gráfico para Mac con funciones de colaboración.",
    link: "https://www.sketch.com/",
    en: "",
  },
  {
    category: "developer",
    name: "InVision Studio",
    money: "Freemium",
    es: "Herramienta en línea para el diseño de interfaces de usuario con funciones de animación y colaboración.",
    link: "https://www.invisionapp.com/studio",
    en: "",
  },
  {
    category: "developer",
    name: "Zapier",
    money: "Freemium",
    es: "Herramienta en línea para la automatización de tareas y flujos de trabajo sin código.",
    link: "https://zapier.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Integromat",
    money: "Freemium",
    es: "Herramienta en línea para la automatización de tareas y flujos de trabajo sin código.",
    link: "https://www.integromat.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Airtable",
    money: "Freemium",
    es: "Plataforma en línea para la creación de bases de datos sin código.",
    link: "https://airtable.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Notion",
    money: "Freemium",
    es: "Plataforma en línea para la creación de wikis, documentos y bases de datos sin código.",
    link: "https://www.notion.so/",
    en: "",
  },
  {
    category: "developer",
    name: "Coda",
    money: "Freemium",
    es: "Plataforma en línea para la creación de documentos y bases de datos interactivos sin código.",
    link: "https://coda.io/",
    en: "",
  },
  {
    category: "developer",
    name: "Glide",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.glideapps.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Adalo",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.adalo.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Thunkable",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://thunkable.com/",
    en: "",
  },
  {
    category: "developer",
    name: "AppSheet",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.appsheet.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Bubble",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones web sin código.",
    link: "https://bubble.io/",
    en: "",
  },
  {
    category: "developer",
    name: "Webflow",
    money: "Freemium",
    es: "Plataforma en línea para la creación de sitios web y tiendas en línea sin código.",
    link: "https://webflow.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Wix",
    money: "Freemium",
    es: "Plataforma en línea para la creación de sitios web y tiendas en línea sin código.",
    link: "https://www.wix.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Squarespace",
    money: "De pago",
    es: "Plataforma en línea para la creación de sitios web y tiendas en línea sin código.",
    link: "https://www.squarespace.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Shopify",
    money: "De pago",
    es: "Plataforma en línea para la creación de tiendas en línea sin código.",
    link: "https://www.shopify.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Glide",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.glideapps.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Adalo",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.adalo.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Thunkable",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://thunkable.com/",
    en: "",
  },
  {
    category: "developer",
    name: "AppSheet",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.appsheet.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Glide",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.glideapps.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Adalo",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.adalo.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Thunkable",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://thunkable.com/",
    en: "",
  },
  {
    category: "developer",
    name: "AppSheet",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones móviles sin código.",
    link: "https://www.appsheet.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Bubble",
    money: "Freemium",
    es: "Plataforma en línea para la creación de aplicaciones web sin código.",
    link: "https://bubble.io/",
    en: "",
  },
  {
    category: "developer",
    name: "Webflow",
    money: "Freemium",
    es: "Plataforma en línea para la creación de sitios web y tiendas en línea sin código.",
    link: "https://webflow.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Wix",
    money: "Freemium",
    es: "Plataforma en línea para la creación de sitios web y tiendas en línea sin código.",
    link: "https://www.wix.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Squarespace",
    money: "De pago",
    es: "Plataforma en línea para la creación de sitios web y tiendas en línea sin código.",
    link: "https://www.squarespace.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Shopify",
    money: "De pago",
    es: "Plataforma en línea para la creación de tiendas en línea sin código.",
    link: "https://www.shopify.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Persado",
    money: "De pago",
    es: "Plataforma de IA para la creación de mensajes de marketing personalizados.",
    link: "https://www.persado.com/",
    en: "",
  },
  {
    category: "developer",
    name: "Albert",
    money: "De pago",
    es: "Plataforma de IA para la automatización de campañas publicitarias en línea.",
    link: "https://albert.ai/",
    en: "",
  },
  {
    category: "developer",
    name: "OpenAI",
    money: "Gratuita",
    es: "Plataforma de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://openai.com/",
    en: "",
  },
  {
    category: "developer",
    name: "TensorFlow",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://www.tensorflow.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Keras",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://keras.io/",
    en: "",
  },
  {
    category: "developer",
    name: "PyTorch",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://pytorch.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Caffe2",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://caffe2.ai/",
    en: "",
  },
  {
    category: "developer",
    name: "Microsoft Cognitive Toolkit",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://www.microsoft.com/en-us/research/project/microsoft-cognitive-toolkit/",
    en: "",
  },
  {
    category: "developer",
    name: "Accord.NET",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://accord-framework.net/",
    en: "",
  },
  {
    category: "developer",
    name: "Theano",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://github.com/Theano/Theano",
    en: "",
  },
  {
    category: "developer",
    name: "Torch",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://pytorch.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Hugging Face",
    money: "Gratuita",
    es: "Plataforma de IA para la creación de chatbots en línea.",
    link: "https://huggingface.co/",
    en: "",
  },
  {
    category: "developer",
    name: "IBM Watson Studio",
    money: "Freemium",
    es: "Plataforma de IA para el análisis y visualización de datos de marketing.",
    link: "https://www.ibm.com/cloud/watson-studio",
    en: "",
  },
  {
    category: "developer",
    name: "Fast.ai",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://www.fast.ai/",
    en: "",
  },
  {
    category: "developer",
    name: "Scikit-learn",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://scikit-learn.org/stable/",
    en: "",
  },
  {
    category: "developer",
    name: "MLlib",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://spark.apache.org/mllib/",
    en: "",
  },
  {
    category: "developer",
    name: "Apache Singa",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://singa.apache.org/",
    en: "",
  },
  {
    category: "developer",
    name: "DeepLearning4J",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://deeplearning4j.org/",
    en: "",
  },
  {
    category: "developer",
    name: "CNTK",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://docs.microsoft.com/en-us/cognitive-toolkit/",
    en: "",
  },
  {
    category: "developer",
    name: "MXNet",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://mxnet.apache.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Mahout",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://mahout.apache.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Shogun",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "http://shogun-toolbox.org/",
    en: "",
  },
  {
    category: "developer",
    name: "Weka",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://www.cs.waikato.ac.nz/ml/weka/",
    en: "",
  },
  {
    category: "developer",
    name: "Intel Deep Learning SDK",
    money: "Gratuita",
    es: "Biblioteca de IA de código abierto para el desarrollo de aplicaciones.",
    link: "https://software.intel.com/content/www/us/en/develop/tools/deep-learning-sdk.html",
    en: "",
  },
  {
    category: "administrative",
    name: "Zoom",
    money: "Gratis",
    es: "Plataforma de videoconferencia con funciones de chat y compartición de pantalla.",
    link: "https://zoom.us/",
    en: "",
  },
  {
    category: "administrative",
    name: "Skype",
    money: "Gratis",
    es: "Plataforma de videoconferencia con funciones de chat y compartición de pantalla.",
    link: "https://www.skype.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Google Meet",
    money: "Gratis",
    es: "Plataforma de videoconferencia con funciones de chat y compartición de pantalla.",
    link: "https://meet.google.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Slack",
    money: "Gratis",
    es: "Plataforma de chat en línea con funciones de colaboración y compartición de archivos.",
    link: "https://slack.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Microsoft Teams",
    money: "Gratis",
    es: "Plataforma de chat en línea con funciones de colaboración y compartición de archivos.",
    link: "https://www.microsoft.com/en-us/microsoft-teams/group-chat-software",
    en: "",
  },
  {
    category: "administrative",
    name: "Asana",
    money: "Gratis",
    es: "Plataforma de gestión de proyectos con funciones de colaboración y seguimiento de tareas.",
    link: "https://asana.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Trello",
    money: "Gratis",
    es: "Plataforma de gestión de proyectos con funciones de colaboración y seguimiento de tareas.",
    link: "https://trello.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Google Drive",
    money: "Gratis",
    es: "Plataforma de almacenamiento en línea con funciones de colaboración y compartición de archivos.",
    link: "https://www.google.com/drive/",
    en: "",
  },
  {
    category: "administrative",
    name: "Dropbox",
    money: "Gratis",
    es: "Plataforma de almacenamiento en línea con funciones de colaboración y compartición de archivos.",
    link: "https://www.dropbox.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "OneDrive",
    money: "Gratis",
    es: "Plataforma de almacenamiento en línea con funciones de colaboración y compartición de archivos.",
    link: "https://www.microsoft.com/en-us/microsoft-365/onedrive/online-cloud-storage",
    en: "",
  },
  {
    category: "administrative",
    name: "Google Docs",
    money: "Gratis",
    es: "Plataforma de edición de documentos en línea con funciones de colaboración y compartición de archivos.",
    link: "https://www.google.com/docs/about/",
    en: "",
  },
  {
    category: "administrative",
    name: "Microsoft Office Online",
    money: "Gratis",
    es: "Plataforma de edición de documentos en línea con funciones de colaboración y compartición de archivos.",
    link: "https://www.office.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Grammarly",
    money: "Gratis",
    es: "Herramienta en línea para la corrección gramatical y ortográfica de textos.",
    link: "https://www.grammarly.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Hemingway",
    money: "Gratis",
    es: "Herramienta en línea para la mejora de la legibilidad y claridad de textos.",
    link: "http://www.hemingwayapp.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Google Keep",
    money: "Gratis",
    es: "Plataforma de notas en línea con funciones de colaboración y compartición de notas.",
    link: "https://keep.google.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Evernote",
    money: "Gratis",
    es: "Plataforma de notas en línea con funciones de colaboración y compartición de notas.",
    link: "https://evernote.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "LastPass",
    money: "Gratis",
    es: "Herramienta en línea para la gestión de contraseñas seguras.",
    link: "https://www.lastpass.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Dashlane",
    money: "Gratis",
    es: "Herramienta en línea para la gestión de contraseñas seguras.",
    link: "https://www.dashlane.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "ZoomIt",
    money: "Gratis",
    es: "Herramienta de presentación en línea con funciones de zoom y anotación.",
    link: "https://docs.microsoft.com/en-us/sysinternals/downloads/zoomit",
    en: "",
  },
  {
    category: "administrative",
    name: "CamScanner",
    money: "Gratis",
    es: "Aplicación móvil para la digitalización de documentos.",
    link: "https://www.camscanner.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "Google Translate",
    money: "Gratis",
    es: "Herramienta en línea para la traducción de textos.",
    link: "https://translate.google.com/",
    en: "",
  },
  {
    category: "administrative",
    name: "LibreOffice",
    money: "Gratis",
    es: "Suite de oficina de código abierto con funciones de edición de documentos, hojas de cálculo y presentaciones.",
    link: "https://www.libreoffice.org/",
    en: "",
  },
  {
    category: "administrative",
    name: "GIMP",
    money: "Gratis",
    es: "Editor de imágenes de código abierto con funciones avanzadas de edición y retoque.",
    link: "https://www.gimp.org/",
    en: "",
  },
  {
    category: "administrative",
    name: "Inkscape",
    money: "Gratis",
    es: "Editor de gráficos vectoriales de código abierto con funciones avanzadas de edición y diseño.",
    link: "https://inkscape.org/",
    en: "",
  },
  {
    category: "artist",
    name: "Adobe Illustrator",
    money: "De pago",
    es: "Software de ilustración vectorial utilizado para crear y editar gráficos vectoriales.",
    link: "https://www.adobe.com/products/illustrator.html",
    en: "",
  },
  {
    category: "artist",
    name: "Sketch",
    money: "De pago",
    es: "Software de diseño vectorial para Mac utilizado por diseñadores de interfaces y productos.",
    link: "https://www.sketch.com/",
    en: "",
  },
  {
    category: "artist",
    name: "Inkscape",
    money: "Gratuita",
    es: "Editor de gráficos vectoriales de código abierto con funciones avanzadas de edición y diseño.",
    link: "https://inkscape.org/",
    en: "",
  },
  {
    category: "artist",
    name: "Figma",
    money: "Freemium",
    es: "Herramienta en línea para el diseño de interfaces de usuario con funciones de colaboración.",
    link: "https://www.figma.com/",
    en: "",
  },
  {
    category: "artist",
    name: "CorelDRAW",
    money: "De pago",
    es: "Software de diseño gráfico y de ilustración vectorial utilizado para crear y editar gráficos y diseños.",
    link: "https://www.coreldraw.com/",
    en: "",
  },
  {
    category: "artist",
    name: "Affinity Designer",
    money: "De pago",
    es: "Software de ilustración vectorial utilizado para crear y editar gráficos vectoriales.",
    link: "https://affinity.serif.com/en-gb/designer/",
    en: "",
  },
  {
    category: "artist",
    name: "Gravit Designer",
    money: "Gratuita",
    es: "Software de diseño vectorial para múltiples plataformas utilizado para crear y editar gráficos vectoriales.",
    link: "https://www.designer.io/",
    en: "",
  },
  {
    category: "artist",
    name: "Canva",
    money: "Freemium",
    es: "Herramienta en línea de diseño gráfico utilizada para crear diseños personalizados para medios sociales y otros usos.",
    link: "https://www.canva.com/",
    en: "",
  },
  {
    category: "artist",
    name: "Procreate",
    money: "De pago",
    es: "Aplicación de ilustración y diseño digital para iPad utilizada por artistas de ilustración y diseño.",
    link: "https://procreate.art/",
    en: "",
  },
  {
    category: "artist",
    name: "Clip Studio Paint",
    money: "De pago",
    es: "Software de ilustración y animación digital utilizado por artistas de ilustración y diseño.",
    link: "https://www.clipstudio.net/en/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Upwork",
    money: "Comisión del 20%",
    es: "Una de las plataformas más grandes de trabajo freelance. Ofrece trabajos en áreas como diseño, redacción, programación, entre otros.",
    link: "https://www.upwork.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Freelancer",
    money: "Comisión del 10% o tarifa plana",
    es: "Otra de las plataformas más grandes de trabajo freelance. Ofrece trabajos en áreas como diseño, redacción, programación, entre otros.",
    link: "https://www.freelancer.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Fiverr",
    money: "Comisión del 20%",
    es: 'Una plataforma para trabajos freelance en áreas como diseño, redacción, programación, entre otros. Ofrece la opción de crear "gigs" con servicios específicos para los clientes.',
    link: "https://www.fiverr.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Workana",
    money: "Comisión del 15%",
    es: "Una plataforma enfocada en trabajos freelance en Latinoamérica. Ofrece trabajos en áreas como diseño, redacción, programación, entre otros.",
    link: "https://www.workana.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Swagbucks",
    money: "Pago por llenar encuestas",
    es: "Una plataforma para ganar dinero llenando encuestas, viendo videos, comprando en línea, entre otros.",
    link: "https://www.swagbucks.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Toluna",
    money: "Pago por llenar encuestas",
    es: "Una plataforma para ganar dinero llenando encuestas. También ofrece pruebas de productos y opinión de contenido.",
    link: "https://www.toluna.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "PrizeRebel",
    money: "Pago por llenar encuestas",
    es: "Una plataforma para ganar dinero llenando encuestas, completando tareas en línea, viendo videos, entre otros.",
    link: "https://www.prizerebel.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Survey Junkie",
    money: "Pago por llenar encuestas",
    es: "Una plataforma para ganar dinero llenando encuestas. Ofrece un sistema de puntos que se pueden canjear por dinero en efectivo o tarjetas de regalo.",
    link: "https://www.surveyjunkie.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Betabound",
    money: "Pago por proyecto",
    es: "Una plataforma para betatesters que ofrece oportunidades para probar aplicaciones y productos.",
    link: "https://www.betabound.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Erli Bird",
    money: "Pago por proyecto",
    es: "Una plataforma para betatesters que ofrece oportunidades para probar aplicaciones y productos.",
    link: "https://erlibird.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Testbirds",
    money: "Pago por proyecto",
    es: "Una plataforma para betatesters que ofrece oportunidades para probar aplicaciones y productos.",
    link: "https://www.testbirds.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "UserTesting",
    money: "Pago por proyecto",
    es: "Una plataforma para betatesters que ofrece oportunidades para probar aplicaciones y productos.",
    link: "https://www.usertesting.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Nubelo",
    money: "Comisión del 10%",
    es: "Una plataforma de trabajo freelance en español. Ofrece trabajos en áreas como diseño, redacción, programación, entre otros.",
    link: "https://www.nubelo.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Freelancer Argentina",
    money: "Comisión del 10% o tarifa plana",
    es: "Una plataforma de trabajo freelance en español enfocada en Argentina. Ofrece trabajos en áreas como diseño, redacción, programación, entre otros.",
    link: "https://www.freelancer.com.ar/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Get on Board",
    money: "Comisión del 9%",
    es: "Una plataforma de trabajo freelance en español enfocada en Chile y Argentina. Ofrece trabajos en áreas como diseño, redacción, programación, entre otros.",
    link: "https://www.getonbrd.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Workhere",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Nueva Zelanda. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.workhere.co.nz/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Remote.co",
    money: "Gratis para los candidatos",
    es: "Una plataforma que ofrece trabajos remotos en diversas áreas, incluyendo tecnología, marketing, diseño, entre otros. También ofrece recursos para los solicitantes de empleo.",
    link: "https://remote.co/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "We Work Remotely",
    money: "Pago por publicidad de trabajo",
    es: "Una plataforma que ofrece trabajos remotos en diversas áreas, incluyendo tecnología, marketing, diseño, entre otros. Se enfoca en trabajos de tiempo completo y no en proyectos.",
    link: "https://weworkremotely.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Toptal",
    money: "Comisión del 10-20%",
    es: "Una plataforma que ofrece trabajos remotos en áreas como desarrollo de software, diseño, finanzas, entre otros. Los candidatos deben pasar por un proceso de selección riguroso para unirse a la plataforma.",
    link: "https://www.toptal.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Remote.de",
    money: "Gratis para los candidatos",
    es: "Una plataforma que ofrece trabajos remotos en diversas áreas, incluyendo tecnología, marketing, diseño, entre otros. Se enfoca en trabajos en Alemania.",
    link: "https://remote.de/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Working Nomads",
    money: "Gratis para los candidatos",
    es: "Una plataforma que ofrece trabajos remotos en diversas áreas, incluyendo tecnología, marketing, diseño, entre otros. Se enfoca en trabajos en Australia y Nueva Zelanda.",
    link: "https://www.workingnomads.co/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Empléate",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Venezuela. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.empleate.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Bumeran",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Venezuela. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.bumeran.com.ve/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Trabajando",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Colombia. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.trabajando.com.co/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Computrabajo",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Colombia. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.computrabajo.com.co/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Elempleo",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Colombia. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.elempleo.com/co/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Laborum",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Colombia. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.laborum.com.co/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Multitrabajos",
    money: "Gratis para los candidatos",
    es: "Una de las plataformas más grandes de trabajo en Ecuador. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.multitrabajos.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Indeed",
    money: "Gratis para los candidatos",
    es: "Una plataforma global para encontrar trabajo que también tiene opciones en Ecuador. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.indeed.com.ec/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Laborum",
    money: "Gratis para los candidatos",
    es: "Una plataforma para encontrar trabajo en Perú. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.laborum.pe/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "InfoJobs",
    money: "Gratis para los candidatos",
    es: "Una de las plataformas más grandes de trabajo en España. Ofrece trabajos en áreas como tecnología, finanzas, salud, entre otros.",
    link: "https://www.infojobs.net/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Freelancer Club",
    money: "Comisión del 10%",
    es: "Una plataforma que conecta a freelancers con trabajos en el Reino Unido. La sección de comunidad ofrece oportunidades para conectarse con otros profesionales y compartir información.",
    link: "https://freelancerclub.net/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Freelance Spain",
    money: "Gratis para los candidatos",
    es: "Una plataforma que conecta a freelancers con trabajos en España. La sección de comunidad ofrece oportunidades para conectarse con otros profesionales y compartir información.",
    link: "https://freelance-spain.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Meetup",
    money: "Gratis para los candidatos",
    es: "Una plataforma que conecta a personas con intereses similares en todo el mundo. Ofrece grupos de meetup específicos para freelancers y profesionales independientes.",
    link: "https://www.meetup.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Freelancermap",
    money: "Gratis para los candidatos",
    es: "Una plataforma que conecta a freelancers y empresas en Europa. La sección de comunidad ofrece oportunidades para conectarse con otros profesionales y compartir información.",
    link: "https://www.freelancermap.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Coworks",
    money: "Gratis para los candidatos",
    es: "Una plataforma que conecta a freelancers con espacios de coworking en América Latina. La sección de comunidad ofrece oportunidades para conectarse con otros profesionales y compartir información.",
    link: "https://coworks.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Malt",
    money: "Comisión del 10%",
    es: "Una plataforma que conecta a freelancers con empresas en Europa. La sección de comunidad ofrece oportunidades para conectarse con otros profesionales y compartir información.",
    link: "https://www.malt.es/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Crehana",
    money: "Pago por curso",
    es: "Una plataforma de aprendizaje en línea enfocada en diseño, ilustración, animación, entre otros.",
    link: "https://www.crehana.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Domestika",
    money: "Pago por curso",
    es: "Una plataforma de aprendizaje en línea enfocada en diseño, ilustración, animación, entre otros.",
    link: "https://www.domestika.org/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Platzi",
    money: "Pago por curso",
    es: "Una plataforma de aprendizaje en línea enfocada en tecnología, marketing, diseño, entre otros.",
    link: "https://platzi.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Udemy",
    money: "Pago por curso",
    es: "Una plataforma de aprendizaje en línea con cursos en una amplia variedad de áreas, incluyendo tecnología, negocios, diseño, entre otros.",
    link: "https://www.udemy.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "LinkedIn Learning",
    money: "Pago mensual o anual",
    es: "Una plataforma de aprendizaje en línea que ofrece cursos en una amplia variedad de áreas, incluyendo tecnología, negocios, diseño, entre otros.",
    link: "https://www.linkedin.com/learning",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Skillshare",
    money: "Pago mensual o anual",
    es: "Una plataforma de aprendizaje en línea enfocada en creatividad, tecnología, negocios, entre otros.",
    link: "https://www.skillshare.com/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "Coursera",
    money: "Pago por curso o suscripción",
    es: "Una plataforma de aprendizaje en línea que ofrece cursos en una amplia variedad de áreas, incluyendo tecnología, negocios, diseño, entre otros.",
    link: "https://www.coursera.org/",
    en: "",
  },
  {
    category: "taskHunter",
    name: "edX",
    money: "Pago por curso o suscripción",
    es: "Una plataforma de aprendizaje en línea que ofrece cursos en una amplia variedad de áreas, incluyendo tecnología, negocios, diseño, entre otros.",
    link: "https://www.edx.org/",
    en: "",
  },
];
