import styles from "./styles.module.scss";

const Ofac = () => {
  return (
    <div className={`w-100 m-0${styles.bgOrange}`}>
      <div className="w-100">
        <h1>OFAC Certification. </h1>
        <p>
          Tigrenator certifies that (i) it is not acting on behalf of any
          person, group, entity, or nation named by any Executive Order or the
          United States Treasury Department, through its Office of Foreign
          Assets Control (“OFAC”) or otherwise, as a terrorist, “Specially
          Designated Nation”, “Blocked Person”, or other banned or blocked
          person, entity, nation, or transaction pursuant to any law, order,
          rule or regulation that is enforced or administered by OFAC or another
          department of the United States government, and (ii) Company is not
          engaged in this transaction on behalf of, or instigating or
          facilitating this transaction on behalf of, any such person, group,
          entity or nation.
        </p>

        <h2>Anti-Laundering/International Trade Law</h2>
        <strong>Compliance Sample Clauses</strong>
        <p>
          Anti-Laundering/International Trade Law Compliance. Subject to the
          Servicer’s knowledge, and after reasonable inquiry, no Covered Entity
          will become a Sanctioned Person. Subject to the Servicer’s knowledge,
          and after reasonable inquiry, no Covered Entity will (i) have any of
          its assets in a Sanctioned Country or in the possession, custody or
          control of a Sanctioned Person in violation of any Anti-Terrorism Law;
          (ii) do business in or with, or derive any of its income from
          investments in or transactions with, any Sanctioned Country or
          Sanctioned Person in violation of any Anti-Terrorism Law; (iii) engage
          in any dealings or transactions prohibited by any Anti-Terrorism Law
          or (iv) use the proceeds of any Purchase to fund any operations in,
          finance any investments or activities in, or, make any payments to, a
          Sanctioned Country or Sanctioned Person in violation of any
          Anti-Terrorism Law. The funds used to repay Servicer’s obligations
          under this Agreement and each of the other Transaction Documents will
          not be derived from any activity in violation of any Anti-Terrorism
          Law. Each Covered Entity shall comply with all Anti-Terrorism Laws.
          Servicer shall promptly notify the Administrator in writing upon the
          occurrence of a Reportable Compliance Event.
        </p>

        <p>
          <strong>KYC</strong> know your customer.
        </p>
        <p>
          At least three (3) Business Days prior to the Effective Date, the
          Administrative Agent and each Lender shall have received all
          documentation and information relating to the Borrower as is
          reasonably requested in writing by the Administrative Agent and/or any
          such Lender at least ten (10) Business Days prior to the Effective
          Date that is required by Governmental Authorities under applicable
          “know your customer” and anti-money laundering rules and regulations,
          including the Patriot Act and the Beneficial Ownership Regulation. If
          the Borrower qualifies as a “legal entity customer” under the
          Beneficial Ownership Regulation and the Administrative Agent or any
          Lender so request at least five (5) Business Days prior to the
          Effective Date, then at least three (3) Business Days prior to the
          Effective Date, the Borrower shall have delivered to the
          Administrative Agent and/or any such Lender a Beneficial Ownership
          Certification in relation to the Borrower.
        </p>
      </div>
    </div>
  );
};

export default Ofac;
