import styles from "./styles.module.scss";

const CodeOfConduct = () => {
  return (
    <div className={`w-100 m-0${styles.bgOrange}`}>
      <div className="w-100">
        <h1>Code of conduct and professional ethics</h1>
        <p>
          We, the undersigned, are designers, developers, creative
          technologists, and multi-disciplinary communicators. We are troubled
          by the present state of our industry and its effects on cultures and
          societies across the world.
        </p>
        <p>We have become part of a professional climate that:</p>
        <ul>
          <li>
            prizes venture capital, profit, and scale over usefulness and
            resonance;
          </li>
          <li>demands a debilitating work-life imbalance of its workers;</li>
          <li>lacks critical diversity in gender, race, and age;</li>
          <li>
            claims to solve problems but favours those of a superficial nature;
          </li>
          <li>
            treats consumers’ personal information as objects to be monetised
            instead of as personal property to be supported and protected; and
          </li>
          <li>
            refuses to address the need to reform policies affecting the
            jurisdiction and ownership of data.
          </li>
        </ul>
        <p>
          Encouraged in these directions, we have applied ourselves toward the
          creation of trivial, undifferentiated apps; disposable social
          networks; fantastical gadgets obtainable only by the affluent;
          products that use emotion as a front for the sale of customer data;
          products that reinforce broken or dishonest forms of commerce; and
          insular communities that drive away potential collaborators and
          well-grounded leaders. Some of us have lent our expertise to
          initiatives that abuse the law and human rights, defeat critical
          systems of encryption and privacy, and put lives at risk. We have
          negated our professions’ potential for positive impact, and are using
          up our time and energy manufacturing demand for things that are
          redundant at best, destructive at worst.
        </p>

        <p>
          There are pursuits more worthy of our dedication. Our abilities can
          benefit areas such as education, medicine, privacy and digital
          security, public awareness and social campaigns, journalism,
          information design, and humanitarian aid. They can transform our
          current systems of finance and commerce, and reinforce human rights
          and civil liberties.
        </p>

        <p>
          It is also our responsibility as members of our industry to create
          positive changes within it. We must work to improve our stances on
          diversity, inclusion, working conditions, and employees’ mental
          health. Failing to address these issues should no longer be deemed
          acceptable by any party.
        </p>

        <p>
          Ultimately, regardless of its area of focus or scale, our work and our
          mindset must take on a more ethical, critical ethos.
        </p>

        <p>
          It is not our desire to take the fun out of life. There should always
          be room for entertainment, personal projects, humour, experimentation,
          and light-hearted use of our abilities.
        </p>

        <p>
          Instead, we are calling for a refocusing of priorities, in favour of
          more lasting, democratic forms of communication. A mind shift away
          from profit-over-people business models and the placing of
          corporations before individuals, toward the exploration and production
          of humble, meaningful work, and beneficial cultural impact.
        </p>

        <h2>Code of conduct</h2>

        <h3>personal act</h3>

        <p>I act ethically and with integrity.</p>
        <p>Comply with all Tigrenator policies.</p>
        <p>We respect the rights of all users.</p>
        <p>
          I will not misuse confidential information or engage in other illegal
          activities.
        </p>
        <p>
          Consider the interests, rights and safety of users. We do not harass,
          bully or discriminate.
        </p>
        <p>
          I will not misrepresent myself or others and will provide truthful and
          correct information.
        </p>
        <p>
          I do not attempt to communicate, accept or initiate payments off-site.
        </p>
        <p>I do not agree to do the work that I cannot do.</p>
        <p>I Do not abuse the services provided by the freelancers hired.</p>

        <h2>User content</h2>
        <p>I am responsible for the content I post on Tigrenator and will:</p>

        <p>
          We do not post content that infringes copyright or other intellectual
          property rights.
        </p>
        <p>We will not post content that violates the law.</p>
        <p>Do not post defamatory content.</p>
        <p>
          We do not post obscene or child pornography. Do not post content that
          contains incomplete, false, or inaccurate information about people,
          products, or services.
        </p>
        <p>
          Do not post content that contains viruses or programming routines
          designed to damage your system.
        </p>
        <p>
          We do not post content that holds freelancers accountable or harms
          their business or reputation.
        </p>

        <h2>Confidentiality</h2>

        <p>We respect confidentiality and privacy.</p>
        <p>
          We will not disclose any information or documents obtained except as
          required by law or as authorized by Tigrenator.
        </p>

        <h2>Communication</h2>

        <p>
          Avoid exaggeration, derogatory remarks, and inappropriate references.
          I will not engage in personal attacks, negative or other unfair
          criticism, or unprofessional conduct.
        </p>

        <h2>Offer</h2>
        <p>
          To avoid fees, I do not bid. We will not participate in projects
          involving illegal activities. I only bid on projects that I plan to
          complete.
        </p>

        <p>spam or advertising</p>

        <p>
          I do not spam or promote my site or services unless I have permission
          to do otherwise. payment
        </p>

        <p>
          Do not use Tigrenator to facilitate currency exchanges, including but
          not limited to cryptocurrencies (Bitcoin, Ethereum, etc.). After the
          work is submitted by the freelancer, I do my duty.
        </p>

        <h2>Scam</h2>
        <p>We do not commit fraud.</p>
        <p>We do not create multiple accounts.</p>
        <p>I do not use the Site to illegally transfer money.</p>
        <p>
          I will not use the Site to generate false feedback about any person,
          product or service.
        </p>
      </div>
    </div>
  );
};

export default CodeOfConduct;
