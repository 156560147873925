/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../core";
import { useNavigate } from "react-router-dom";

const commonStyle: string = "0.5px solid #7e82994d";

const linkList = [
  { name: "Service Terms", route: "/terms" },
  { name: "Refund Policy", route: "/terms/refund" },
  { name: "Privacy Policy", route: "/terms/privacy" },
  { name: "Cookies", route: "/terms/cookies" },
  { name: "EULA", route: "/terms/eula" },
  { name: "OFAC", route: "/terms/ofac" },
  { name: "Disclaimers", route: "/terms/disclaimer" },
  { name: "Code of Conduct", route: "/terms/conduct" },
];

// Footer Component
const Footer: FC = () => {
  const { classes } = useLayout();
  const nowrapSt: any = { whiteSpace: "nowrap" };
  const navigate = useNavigate();

  const getStyles = (index: number) => {
    const response = { ...nowrapSt };
    if (index === 0) response.borderLeft = commonStyle;
    response.borderRight = commonStyle;
    return response;
  };

  const goToLink = (link: string) => {
    navigate(link);
  };

  return (
    <div className="footer py-4 d-flex flex-lg-row" id="kt_footer">
      <div
        className={`${classes.footerContainer} d-flex flex-row flex-lg-row flex-stack overflow-scroll`}
      >
        <div className="d-flex flex-row flex-nowrap text-dark">
          <p
            className="text-muted text-hover-primary fw-bold m-0 mr-2 fs-6"
            style={nowrapSt}
          >
            Tigrenator Global Corporation
          </p>
          <p className="text-gray-400 fw-bold m-0 px-2" style={nowrapSt}>
            todos los derechos reservados
          </p>
        </div>

        <div className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          {linkList.map((link, index) => (
            <div
              className="menu-item"
              key={link.name}
              onClick={() => goToLink(link.route)}
            >
              <span className="menu-link px-2" style={getStyles(index)}>
                {link.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Footer };
