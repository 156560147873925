import { FC, createContext, useState } from "react";
import {
  ICtxComponent,
  ECategory,
  Category,
  ECalculatorType,
  ITask,
  ICalculatorContext,
} from "../../models";

export const CalculatorContext = createContext<ICalculatorContext>({
  calculatorType: ECalculatorType.project,
  setCalculatorType: () => {},
  category: ECategory.artist,
  setCategory: () => {},
  subcategoryID: "",
  setSubcategoryID: () => {},
  projectResult: [],
  setProjectResult: () => {},
  currentTag: undefined,
  setCurrentTag: () => {},
});

const CalculatorProvider: FC<ICtxComponent> = ({ children }) => {
  const [calculatorType, setCalculatorType] = useState<ECalculatorType>(
    ECalculatorType.project
  );

  const [category, setCategorySt] = useState<ECategory>(ECategory.artist);
  const [subcategoryID, setSubcategoryIDState] = useState<string>("");
  const [projectResult, setProjectResult] = useState<ITask[]>([]);
  const [currentTag, setCurrentTag] = useState<ITask>();

  const setCategory = (value: string) => {
    Object.values(Category).forEach(
      (current) => current === value && setCategorySt(current)
    );
  };

  const setSubcategoryID = (value: string) => setSubcategoryIDState(value);

  return (
    <CalculatorContext.Provider
      value={{
        calculatorType,
        setCalculatorType,

        category,
        setCategory,

        subcategoryID,
        setSubcategoryID,
        projectResult,
        setProjectResult,
        currentTag,
        setCurrentTag,
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};

export default CalculatorProvider;
