import LottiePlayer from "../../../components/Lottie";
import useCalculator from "../../../hooks/useCalculator";
import { ITask } from "../../../../models";
import styles from "./styles.module.scss";
import { v4 as uuid } from "uuid";

const ProjectList = () => {
  const { projectResult, setCurrentTag } = useCalculator();

  const tagAction = (item: ITask) => {
    setCurrentTag(item);
    const form: any = document.getElementById("project-select-form");
    form?.reset();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return projectResult.length === 0 ? (
    <div
      className={`w-100 d-flex justify-content-center p-10 ${styles.fadeInFwd}`}
    >
      <LottiePlayer />
    </div>
  ) : (
    <div className="w-100 d-flex justify-content-start p-10 flex-wrap">
      {projectResult.map((item: ITask) => (
        <div
          key={uuid()}
          className={`${styles.tag} ${styles.fadeInFwd}`}
          onClick={() => tagAction(item)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default ProjectList;
