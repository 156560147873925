import { uuidv4 } from "@firebase/util";
import { useEffect, useState } from "react";
import { robots } from "../../../../utils/robots";
import LottiePlayer from "../../../components/Lottie";
import { ICategoryProperty } from "../../../contexts/models";
import useCalculator from "../../../hooks/useCalculator";
import useFirebase from "../../../hooks/useFirebase";
import CategoryCard from "../../calculator/components/CategoryCard";
import styles from "./styles.module.scss";

const Bots = () => {
  const [categoryArray, setCategoryArr] = useState<ICategoryProperty[]>();
  const [robotList, setRobotList] = useState<any>(robots);
  const { category } = useCalculator();
  const { categories } = useFirebase();

  const goToLink = (link: string) => window.open(link);

  useEffect(() => {
    if (categories !== undefined) {
      setCategoryArr(Object.values(categories));
    }
  }, [categories]);

  useEffect(() => {
    if (["", undefined, null].includes(category)) {
      setRobotList(robots);
      return;
    }

    const filtered = robots.filter((bot) => bot.category === category);

    setRobotList(filtered);
  }, [category]);

  return (
    <div>
      <div className="d-flex flex-direction-row justify-content-center mb-5">
        {categoryArray !== undefined ? (
          categoryArray.map((item) => (
            <CategoryCard item={item} key={item.name} />
          ))
        ) : (
          <LottiePlayer />
        )}
      </div>
      <div
        className={`d-flex flex-column justify-content-stretch align-items-stretch mb-5 ${styles.cont}`}
      >
        {robotList.map((item: any) => (
          <div className={styles.bot} key={uuidv4()}>
            <div>
              <p>{item.name}</p>
              <p>{item.name}</p>
            </div>
            <div>{item.es}</div>
            <div>
              <button onClick={() => goToLink(item.link)}>Ir al sitio</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bots;
