import { useContext } from "react";
import { CalculatorContext } from "../contexts/Calculator";
import { ICalculatorContext } from "../../models";

const useCalculator = () => {
  const {
    calculatorType,
    setCalculatorType,
    category,
    setCategory,

    subcategoryID,
    setSubcategoryID,
    projectResult,
    setProjectResult,
    currentTag,
    setCurrentTag,
  } = useContext<ICalculatorContext>(CalculatorContext);

  return {
    calculatorType,
    setCalculatorType,
    category,
    setCategory,

    subcategoryID,
    setSubcategoryID,

    projectResult,
    setProjectResult,

    currentTag,
    setCurrentTag,
  };
};

export default useCalculator;
