/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, ChangeEvent } from "react";
import { KTSVG } from "../../../helpers";
import styles from "./styles.module.scss";
import useCalculator from "../../../../app/hooks/useCalculator";
// import { tasks } from "../../../../utils/tasks";
import useFirebase from "../../../../app/hooks/useFirebase";
import { useLocation } from "react-router-dom";
import useTalent from "../../../../app/hooks/useTalent";
import { variants } from "../../../../utils/constants";
import {
  actionWithPercent,
  getSalaryHoursNum,
} from "../../../../utils/functions";
import {
  ECalculatorType,
  ICategoryProperty,
  ITask,
  ETalentType,
} from "../../../../models";

const filterInitialState = {
  client: "startup",
  experience: "junior",
  priority: "mid",
};

/* eslint-disable no-unreachable */
const Toolbar = () => {
  const location = useLocation();
  const { categories, subcategories, tasks } = useFirebase();
  const { talentType, setTalentType } = useTalent();
  const { setProjectResult, currentTag, calculatorType, setCalculatorType } =
    useCalculator();

  const [search, setSearch] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [salary, setSalary] = useState<number>(0);
  const [filterSalary, setFilterSalary] = useState<number>(0);
  const [filter, setFilter] = useState<any>(filterInitialState);
  const [allTasks, setAllTasks] = useState<ITask[]>([]);

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>, value: any) => {
    const filterCopy: any = { ...filter };
    filterCopy[value] = e.target.value;
    setFilter(filterCopy);
  };

  useEffect(() => {
    const hours =
      typeof currentTag?.hours === "string"
        ? parseInt(currentTag.hours)
        : currentTag?.hours;

    if (hours === undefined) return;

    const newSalary = actionWithPercent(
      getSalaryHoursNum(salary) * hours,
      filter
    );

    const valueIsNan = isNaN(newSalary);
    setFilterSalary(valueIsNan ? 0 : newSalary);
  }, [filter]);

  useEffect(() => {
    setAllTasks(tasks === undefined ? [] : tasks);
  }, [tasks]);

  useEffect(() => {
    if (search !== "") {
      if (category === "") {
        let searchResult = tasks.filter((task: ITask) =>
          new RegExp(search.toLowerCase()).test(task.name.toLowerCase())
        );

        setProjectResult(searchResult);
      } else {
        const preresult = tasks.filter(
          (task: ITask) =>
            task.category.toLowerCase() ===
            category.toLowerCase().split(" ").join("")
        );

        let searchResult = preresult.filter((task: ITask) =>
          new RegExp(search.toLowerCase()).test(task.name.toLowerCase())
        );

        setProjectResult(searchResult);
      }
    } else {
      setProjectResult([]);
    }
  }, [search, category]);

  useEffect(() => {
    if (currentTag === undefined) {
      setSalary(0);
      return;
    }

    const tmp = subcategories.find(
      (item) => item.id === currentTag.subcategoryId
    );

    if (tmp === undefined) {
      setSalary(0);
      return;
    }

    setFilter(filterInitialState);
    setFilterSalary(0);
    setSalary(
      typeof tmp.baseSalary === "string"
        ? parseInt(tmp.baseSalary)
        : tmp.baseSalary
    );
  }, [currentTag]);

  return (
    <div id="kt_layout_toolbar" className={`d-none bg-white`}>
      {/* begin::Wrapper */}
      <div
        className={`w-100 d-flex align-items-center justify-content-start ${styles.bgWhite}`}
      >
        <>
          {location.pathname === "/calculator" && (
            <div className={styles.btnCont}>
              <button
                onClick={() => setCalculatorType(ECalculatorType.project)}
                className={`btn btn-light m-2 text-nowrap ${
                  calculatorType === ECalculatorType.project && "text-black"
                }`}
              >
                Por proyecto
              </button>
              <button
                onClick={() => setCalculatorType(ECalculatorType.hour)}
                className={`btn btn-light m-2 text-nowrap ${
                  calculatorType === ECalculatorType.hour && "text-black"
                }`}
              >
                Por hora
              </button>
              <button
                onClick={() => setCalculatorType(ECalculatorType.salary)}
                className={`btn btn-light m-2 text-nowrap ${
                  calculatorType === ECalculatorType.salary && "text-black"
                }`}
              >
                Sueldo
              </button>
            </div>
          )}

          {location.pathname === "/talent" && (
            <div className="d-flex align-items-start justify-content-start">
              {/*
              <button
                onClick={() => setTalentType(ETalentType.human)}
                className={`btn btn-light m-2 ${
                  talentType === ETalentType.human && "text-black"
                }`}
              >
                Humano
              </button>
              */}
              <button
                onClick={() => setTalentType(ETalentType.bots)}
                className={`btn btn-light m-2 ${
                  talentType === ETalentType.bots && "text-black"
                }`}
              >
                Robots
              </button>
            </div>
          )}
        </>
      </div>

      {/* begin::body */}
      <div
        className={`w-100 d-flex align-items-center justify-content-center ${styles.bgSoftOrange}`}
      >
        <div className={`container-xxl pt-10 pb-8 m-0`}>
          <div className="d-flex align-items-center">
            <h1
              className={`fw-bold me-3 text-black asap ${styles.title} ${styles.fadeIn}`}
            >
              {location.pathname === "/calculator" &&
                variants[calculatorType].title}

              {location.pathname === "/talent" && variants[talentType].title}
            </h1>
          </div>

          <div className={`d-flex flex-column`}>
            <div
              className={`d-lg-flex align-lg-items-center ${styles.paragraph} ${styles.fadeIn}`}
            >
              {location.pathname === "/calculator" &&
                variants[calculatorType].text}

              {location.pathname === "/talent" && variants[talentType].text}
            </div>
          </div>
        </div>
      </div>
      {/*end::body*/}

      {location.pathname === "/calculator" &&
        calculatorType === "project" &&
        allTasks.length !== 0 && (
          <div
            className={`w-100 d-flex align-items-center justify-content-center ${styles.bgDarkBlue} ${styles.fadeInLeft}`}
          >
            <div className="container-xxl pt-10 pb-8">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center w-xxl-700px">
                  <h1 className="fw-bold me-3 text-white">
                    Búsqueda de Proyectos
                  </h1>

                  <span className="fw-bold text-white opacity-50">
                    Busca por nombre de proyecto
                  </span>
                </div>

                <div className={"d-flex align-items-center ms-12"}>
                  <span
                    className={`fw-bold text-white opacity-50 ${styles.inDesktop}`}
                  >
                    {currentTag?.name}
                  </span>
                </div>
              </div>

              <div className={`d-flex flex-column`}>
                <div className="d-lg-flex align-lg-items-center">
                  <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5 w-xxl-700px h-lg-60px me-lg-10 my-5">
                    <div className="row flex-grow-1  mb-lg-0">
                      <div className="col-lg-8 d-flex align-items-center mb-lg-0">
                        <KTSVG
                          path="/media/icons/duotune/general/gen021.svg"
                          className="svg-icon-1 svg-icon-gray-400 me-1"
                        />
                        <input
                          type="search"
                          className="form-control form-control-flush flex-grow-1"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Your Search"
                          autoComplete="off"
                        />
                      </div>

                      <div className="col-lg-4 d-flex align-items-center mb-lg-0">
                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                        <KTSVG
                          path="/media/icons/duotune/general/gen025.svg"
                          className="svg-icon-1 svg-icon-gray-400 me-1"
                        />
                        <select
                          className="form-select border-0 flex-grow-1"
                          data-control="select2"
                          data-placeholder="Category"
                          data-hide-search="true"
                          defaultValue={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value={""}>Todas</option>
                          {categories.map((cat: ICategoryProperty) => (
                            <option value={cat.name} key={cat.name}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* begin::Action 
                  <div className="min-w-150px text-end">
                    <button
                      type="submit"
                      className="btn btn-dark"
                      id="kt_advanced_search_button_1"
                    >
                      Search
                    </button>
                  </div>
                  */}
                  </div>

                  {currentTag !== undefined && (
                    <div className="d-flex flex-direction-row flex-wrap align-items-center justify-content-start">
                      <div className="m-2">
                        <span className="fw-bold text-white opacity-50 whitespace-nowrap asap">
                          Tarifa estandar
                          <span
                            className={`fw-bold text-white ${styles.inMobile}`}
                          >
                            {`${currentTag?.name}`}
                          </span>
                        </span>
                        <div className={`fw-bold ${styles.big} asap`}>
                          <>
                            {filterSalary > 0
                              ? `$${filterSalary.toFixed(2)}`
                              : `$${
                                  salary !== 0 && currentTag
                                    ? (
                                        getSalaryHoursNum(salary) *
                                        currentTag?.hours
                                      ).toFixed(2)
                                    : "0"
                                }`}
                          </>
                        </div>
                      </div>

                      <div className="m-2">
                        <span className="fw-bold text-white opacity-50 whitespace-nowrap asap">
                          Horas de trabajo
                        </span>
                        <div className={`fw-bold ${styles.mid} asap`}>
                          {["", "-", 0].includes(currentTag?.hours)
                            ? 0
                            : currentTag?.hours}
                          H
                        </div>
                      </div>

                      <div className="m-2">
                        <span className="fw-bold text-white opacity-50 whitespace-nowrap asap">
                          Experiencia Minima
                        </span>
                        <div className={`fw-bold ${styles.mid} asap`}>
                          {currentTag?.level}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

      {location.pathname === "/calculator" &&
        calculatorType === "project" &&
        currentTag !== undefined && (
          <div
            className={`w-100 d-flex align-items-center justify-content-end py-3`}
            style={{ borderBottom: "1px solid #D8D8D8" }}
          >
            <form id="project-select-form" className={styles.formCont}>
              <select
                className="form-select me-2 custom-select"
                aria-label="Default select example"
                defaultValue="startup"
                onChange={(e) => onSelectChange(e, "client")}
              >
                <option value="particular">Particular</option>
                <option value="startup">Emprendimiento</option>
                <option value="company">Empresa</option>
              </select>

              <select
                className="form-select me-2 custom-select"
                aria-label="Default select example"
                defaultValue="junior"
                onChange={(e) => onSelectChange(e, "experience")}
              >
                <option value="noob">Sin Experiencia</option>
                <option value="junior">Junior</option>
                <option value="senior">Senior</option>
                <option value="expert">Experto</option>
              </select>

              <select
                className="form-select me-2 custom-select"
                aria-label="Default select example"
                defaultValue="mid"
                onChange={(e) => onSelectChange(e, "priority")}
              >
                <option value="low">Bajo</option>
                <option value="mid">Media</option>
                <option value="high">Para ayer</option>
              </select>
            </form>
          </div>
        )}
    </div>
  );
};
export { Toolbar };
