import { useState } from "react";
import Lottie from "react-lottie";
import Loader from "./assets/loader.json";
import styles from "./styles.module.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LottiePlayer = () => {
  const [animation] = useState({
    isStopped: false,
    isPaused: false,
    isClickToPauseDisabled: true,
  });

  return (
    <div className={styles.loaderCont}>
      <Lottie
        options={defaultOptions}
        width={200}
        height={200}
        isPaused={animation.isPaused}
        isStopped={animation.isStopped}
        isClickToPauseDisabled={animation.isClickToPauseDisabled}
      />
    </div>
  );
};

export default LottiePlayer;
