/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, FC } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Footer } from "../../../_metronic/layout/components/Footer";
import styles from "./styles.module.scss";

interface VideoCardProps {
  url: string;
  name: string;
  title: string;
  image: string;
  text: string;
}

const VideoCard: FC<VideoCardProps> = ({
  /* url, name,*/
  title,
  image,
  text,
}) => {
  return (
    <div className={styles.videoCard}>
      {/*
      <iframe
        width="290"
        height="174"
        src={url}
        title={name}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      */}

      <img
        src={image}
        width={290}
        height={174}
        className={styles.image}
        alt={title}
      />

      <p className={`${styles.videoTitle} poppins`}>{title}</p>
      <p className={`${styles.videoText} poppins`}>{text}</p>
    </div>
  );
};

const videoList = [
  {
    name: "WYKO &amp; SIIK - 2AM (feat. shi’tz) [NCS Release]",
    url: "https://www.youtube.com/embed/Kd3u_sNzfiI",
    image: "/media/images/video1.png",
    title: "Muestrate ante el mundo!",
    text: `
        Puedes ser descubierto por tu próximo cliente o encontrar aliados en
        otros oficios digitales con los que colaborar.
      `,
  },
  {
    name: "Cesqeaux &amp; Tisoki - Give It To Me [NCS Release]",
    url: "https://www.youtube.com/embed/VxRZWnvMOHo",
    title: "Todo desde tu pais",
    image: "/media/images/video2.png",
    text: `
        Cobra a tus clientes internacionales desde Venezuela con comisiones muy
        bajas.
      `,
  },
  {
    name: "ANGELPLAYA - PSYCHOPATHIC [NCS Release]",
    url: "https://www.youtube.com/embed/R9LrSuGqQRo",
    title: "Tu, tus colegas y conocidos",
    image: "/media/images/video3.png",
    text: `
        Recomendamos la mejor combinación entre un directorio de freelancers
        verificados y listas de herramientas IA.
      `,
  },
];

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <>
      <div className={styles.mainView}>
        <div className={styles.leftCont}>
          <Outlet />
        </div>

        <div className={styles.rightCont}>
          <div className={styles.sub}>
            <img
              style={{ width: "100%" }}
              src={toAbsoluteUrl("/media/logos/tigrenator.svg")}
              alt={"sit"}
            />
            <h2 className="asap">Una plataforma completa para freelancers.</h2>
            <h3 className="asap">
              Directorio inteligente y facturación desde LATAM al resto del
              mundo.
            </h3>
          </div>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <div className={styles.sub}>
          <div className={styles.cont}>
            <h1 className={`${styles.title} poppins`}>
              Directorio inteligente y cobros con facturación desde Venezuela al
              resto del mundo.
            </h1>

            <div className={styles.cont}>
              <div className={styles.list}>
                {videoList.map((item) => (
                  <VideoCard {...item} key={item.url} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<Registration />} />
    </Route>
  </Routes>
);

export { AuthPage };
