import { FC, useEffect, useState } from "react";
import { ISubcategory } from "../../../../models";
import useCalculator from "../../../hooks/useCalculator";
import useFirebase from "../../../hooks/useFirebase";
import styles from "./styles.module.scss";
import { v4 as uuid } from "uuid";

interface ISubCategoryList {
  onReset: Function;
}

const SubCategoryList: FC<ISubCategoryList> = ({ onReset }) => {
  const { subcategories } = useFirebase();
  const { category, subcategoryID, setSubcategoryID } = useCalculator();
  const [list, setList] = useState<ISubcategory[]>([]);

  const onClick = (id: string) => {
    onReset();
    setSubcategoryID(id);
  };

  useEffect(() => {
    setList(
      subcategories.filter(
        (subcategory: ISubcategory) => subcategory.category === category
      )
    );
  }, [category]);

  return (
    <div className={styles.list}>
      {list.map((current) => (
        <div
          key={uuid()}
          onClick={() => onClick(current.id)}
          className={
            subcategoryID === current.id ? styles.itemActive : styles.item
          }
        >
          {current.name}
        </div>
      ))}
    </div>
  );
};

export default SubCategoryList;
