import { useEffect } from "react";
import CategoryList from "./CategoryList";
import useCalculator from "../../../hooks/useCalculator";
import useFirebase from "../../../hooks/useFirebase";
import ProjectList from "./ProjectList";
import styles from "./styles.module.scss";

export const Calculator = () => {
  const { calculatorType } = useCalculator();
  const { getConfig } = useFirebase();

  useEffect(() => {
    document.getElementById("kt_layout_toolbar")?.classList.remove("d-none");
    getConfig();
    return () => {
      document.getElementById("kt_layout_toolbar")?.classList.add("d-none");
    };
  }, []);

  const componentList: any = {
    project: <ProjectList />,
    salary: <CategoryList />,
    hour: <CategoryList />,
  };

  return (
    <div className={`w-100 m-0${styles.bgOrange}`}>
      <div className="w-100 d-flex flex-direction-row">
        {componentList[calculatorType]}
      </div>
    </div>
  );
};
