import { useEffect } from "react";
import useTalent from "../../../hooks/useTalent";
import styles from "./styles.module.scss";
import Bots from "./Bots";
import useFirebase from "../../../hooks/useFirebase";
import { useNavigate } from "react-router-dom";

const Human = () => <>Human</>;

const Talent = () => {
  const { talentType } = useTalent();
  const { getConfig /* addNewConfig */ } = useFirebase();
  const router = useNavigate();

  useEffect(() => {
    window.open("https://go.tigrenator.com/");
    router("/calculator");

    document.getElementById("kt_layout_toolbar")?.classList.remove("d-none");
    getConfig();
    return () => {
      document.getElementById("kt_layout_toolbar")?.classList.add("d-none");
    };
  }, []);

  const componentList: any = {
    human: <Human />,
    bots: <Bots />,
  };

  return (
    <div className={`w-100 m-0${styles.bgOrange}`}>
      <div className="w-100">{componentList[talentType]}</div>
    </div>
  );
};

export default Talent;
