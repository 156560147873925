/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import Terms from "./components/Terms";
import Privacy from "./components/PrivacyPolicy";
import Refund from "./components/Refund";
import Cookies from "./components/Cookies";
import Eula from "./components/Eula";
import Ofac from "./components/Ofac";
import Disclaimer from "./components/Disclaimer";
import Conduct from "./components/Ethic";
import { MasterLayout } from "../../../_metronic/layout/MasterLayout";

const TermsPage = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route index element={<Terms />} />
        <Route path="terms" element={<Terms />} />
        <Route path="refund" element={<Refund />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="cookies" element={<Cookies />} />
        <Route path="eula" element={<Eula />} />
        <Route path="ofac" element={<Ofac />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="conduct" element={<Conduct />} />
      </Route>
    </Routes>
  );
};

export { TermsPage };
