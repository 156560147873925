import axios from "axios";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}/api`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  },
});
