/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { getAuth } from "../auth";
import { Calculator } from "./components/Calculator";
import { MasterLayout } from "../../../_metronic/layout/MasterLayout";

const CalculatorPage = () => {
  const isAuth = getAuth();
  return (
    <Routes>
      <Route element={isAuth ? <Calculator /> : <MasterLayout />}>
        <Route path="calculator" element={<Calculator />} />
        <Route index element={<Calculator />} />
      </Route>
    </Routes>
  );
};

export { CalculatorPage };
