import { ChangeEvent, useEffect, useState } from "react";
import CategoryCard from "./CategoryCard";
import SubCategoryList from "./SubCategoryList";
import { operation, percentage } from "../../../../utils/functions";
import styles from "./styles.module.scss";
import useFirebase from "../../../hooks/useFirebase";
import LottiePlayer from "../../../components/Lottie";
import useCalculator from "../../../hooks/useCalculator";
import { additional, filterValues } from "../../../../utils/constants";
import { v4 as uuid } from "uuid";
import {
  ICategoryProperty,
  ECalculatorType,
  ILifeCost,
  ISubcategory,
  IAdditional,
} from "../../../../models";
import SalaryIndicator from "./SalaryIndicator";

const filterInitialState = {
  experience: "junior",
  availability: "full",
};

const CategoryList = () => {
  const { categories, subcategories, lifeCost } = useFirebase();
  const { category, subcategoryID, calculatorType } = useCalculator();
  const [subcategory, setSubcategory] = useState<ISubcategory | undefined>();
  const [salary, setSalary] = useState<number>(0);
  const [salaryImp, setSalaryImp] = useState<number>(0);
  const [filter, setFilter] = useState<any>(filterInitialState);
  const [country, setCountry] = useState<ILifeCost>();
  const [isChecked, setChecked] = useState<boolean>(false);
  const [additionalList, setAditionalList] =
    useState<IAdditional[]>(additional);

  const resetSelect = () => {
    setSalaryImp(0);
    const form: any = document.getElementById("category-select-form");
    form?.reset();
  };

  const allEmpty = (tmpCategory: ICategoryProperty | undefined = undefined) => {
    resetSelect();
    setFilter(filterInitialState);
    setAditionalList([]);
    setSalary(0);
    setCountry(undefined);
    setSubcategory({
      id: tmpCategory ? tmpCategory.id : "",
      name: tmpCategory ? tmpCategory?.name : "",
      es: tmpCategory ? tmpCategory?.description?.es : "",
      en: tmpCategory ? tmpCategory?.description?.en : "",
      internSalary: 0,
      partTime: 0,
      hour: 0,
      freelancerHour: 0,
      baseSalary: 0,
      category: "",
    });
    setAditionalList(additional);
  };

  const onChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilter(filterInitialState);
    resetSelect();
    setCountry(
      lifeCost.find((item: ILifeCost) => item.country === e.target.value)
    );
  };

  const onCheck = (id: string, value: boolean) => {
    const copy = [...additionalList];

    const newAdditional = copy.map((item) => {
      if (item.id === id) {
        item.checked = value;
      }

      return item;
    });

    setAditionalList(newAdditional);
  };

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>, value: any) => {
    const filterCopy: any = { ...filter };
    filterCopy[value] = e.target.value;
    setFilter(filterCopy);
  };

  useEffect(() => {
    if (categories.length > 0) {
      const tmpCategory = categories.find(
        (cat: ICategoryProperty) => cat.id === category
      );
      if (subcategoryID === "") {
        if (tmpCategory !== undefined) {
          allEmpty(tmpCategory);
          return;
        }
      }

      const tmpSubcategory = subcategories.find(
        (sub: ISubcategory) => sub.id === subcategoryID
      );

      if (tmpSubcategory === undefined) {
        allEmpty(tmpCategory);
        return;
      }

      setSubcategory(tmpSubcategory);
      setSalary(
        typeof tmpSubcategory.baseSalary === "string"
          ? parseInt(tmpSubcategory.baseSalary)
          : tmpSubcategory.baseSalary
      );
    }
  }, [category, subcategoryID]);

  useEffect(() => {
    const salaryCopy = salary;
    const results = additionalList.map((item) =>
      item.checked ? percentage(salaryCopy, item.value) : 0
    );

    const subtotal = results.reduce((total, current) => total + current);
    const total = salary + subtotal;

    const experiencePercent = percentage(
      total,
      filterValues.experience[filter.experience].value
    );

    const experienceValue = operation(
      filterValues.experience[filter.experience].action,
      total,
      experiencePercent
    );

    const availabilityPercent = percentage(
      experienceValue,
      filterValues.availability[filter.availability].value
    );

    const availabilityValue = operation(
      filterValues.availability[filter.availability].action,
      experienceValue,
      availabilityPercent
    );

    setSalaryImp(availabilityValue);
  }, [additionalList, filter]);

  return (
    <div className={styles.bigcont}>
      <div className={styles.supercont}>
        {categories?.length > 0 ? (
          categories.map((item: ICategoryProperty) => (
            <CategoryCard item={item} key={uuid()} />
          ))
        ) : (
          <LottiePlayer />
        )}
      </div>

      <div className="d-flex flex-direction-row justify-content-center mb-5">
        <SubCategoryList onReset={resetSelect} />
      </div>

      <div className={styles.infoCont}>
        <div className={`${styles.leftSide}`}>
          <div className={`d-flex align-items-center justify-content-center`}>
            <div className={`pt-10 pb-8`}>
              <div className="d-flex align-items-center">
                <h1
                  className={`fw-bold me-3 mb-5 text-black ${styles.title} asap`}
                >
                  {subcategory?.name}
                </h1>
              </div>
              <div className={`d-flex flex-column`}>
                <div className={`d-lg-flex align-lg-items-center`}>
                  {subcategory?.es}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.indicatorDesk}>
            <SalaryIndicator
              {...{
                salary,
                calculatorType,
                country,
                salaryImp,
                isChecked,
              }}
            />
          </div>
        </div>

        {subcategoryID !== "" && (
          <div className={`${styles.rightSide}`}>
            <div className={`d-flex align-items-center justify-content-center`}>
              <div
                className={`pt-10 pb-8 m-0 d-flex justify-content-start w-100`}
              >
                <div>
                  <div
                    className={`d-flex align-items-start mb-5 ${styles.selectCont} fadeInLeft`}
                  >
                    <select
                      className="form-select me-2 custom-select"
                      aria-label="Default select example"
                      defaultValue={""}
                      onChange={(e) => onChangeCountry(e)}
                    >
                      <option value={""}>Ubicación</option>
                      {lifeCost.map((item: ILifeCost) => (
                        <option value={item.country} key={item.country}>
                          {item.country}
                        </option>
                      ))}
                    </select>

                    <form
                      id="category-select-form"
                      style={{ display: "inherit", width: "100%" }}
                    >
                      {country !== undefined && (
                        <select
                          className="form-select me-2 custom-select fadeInLeft"
                          aria-label="Experiencia"
                          defaultValue="junior"
                          onChange={(e) => onSelectChange(e, "experience")}
                        >
                          <option value="noob">Sin Experiencia</option>
                          <option value="junior">Junior</option>
                          <option value="senior">Semi Senior</option>
                          <option value="expert">Senior</option>
                        </select>
                      )}

                      {country !== undefined &&
                        calculatorType === ECalculatorType.salary && (
                          <select
                            className="form-select me-2 custom-select fadeInLeft"
                            aria-label="Disponibilidad"
                            defaultValue={"full"}
                            onChange={(e) => onSelectChange(e, "availability")}
                          >
                            <option value="full">Tiempo Completo</option>
                            <option value="part">Medio Tiempo</option>
                            <option value="intern">Pasantía</option>
                          </select>
                        )}
                    </form>
                  </div>

                  <div className={`d-flex flex-column fadeInLeft`}>
                    <div className={`d-lg-flex align-lg-items-center`}>
                      Datos obtenidos en NUMBEO según ubicación
                    </div>

                    <div className={`d-lg-flex align-lg-items-center  asap`}>
                      <span className="fs-3 text fw-bold asap">
                        Costo de la vida:
                      </span>
                    </div>

                    {country ? (
                      <>
                        <div
                          className={`d-lg-flex align-lg-items-center fw-bold asap  mb-2`}
                        >
                          ${country?.month} Gastos mensuales estimados de una
                          sola persona.
                        </div>

                        <div
                          className={`d-lg-flex align-lg-items-center ${styles.check}`}
                        >
                          {calculatorType === ECalculatorType.hour && (
                            <span className="fw-bold asap ms-0">
                              ${country?.rent} Apartamento (1 dormitorio) en el
                              centro de la ciudad
                            </span>
                          )}

                          {calculatorType === ECalculatorType.salary && (
                            <>
                              <label className="form-check-label">
                                <input
                                  onChange={(e) => setChecked(e.target.checked)}
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckIndeterminate"
                                />
                              </label>
                              <span className="fw-bold asap">
                                ${country?.rent} Apartamento (1 dormitorio) en
                                el centro de la ciudad
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <div
                        className={`d-lg-flex align-lg-items-center fw-bold asap mb-2`}
                        style={{ color: "tomato" }}
                      >
                        Selecciona una ubicación.
                      </div>
                    )}

                    {calculatorType !== ECalculatorType.hour && (
                      <>
                        <div
                          className={`d-lg-flex align-lg-items-center mt-10 mb-5`}
                        >
                          <span className="fs-3 text fw-bold">Beneficios:</span>
                        </div>

                        {country ? (
                          <>
                            {additionalList.map((item: IAdditional) => (
                              <div
                                className={`d-lg-flex align-lg-items-center ${styles.check} mb-5`}
                                key={uuid()}
                              >
                                <label className="form-check-label">
                                  <input
                                    id={item.id}
                                    onChange={(e) =>
                                      onCheck(item.id, e.target.checked)
                                    }
                                    className="form-check-input additional"
                                    type="checkbox"
                                    checked={item.checked}
                                  />
                                </label>

                                <span>{item.name}</span>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div
                            className={`d-lg-flex align-lg-items-center fw-bold asap mb-2`}
                            style={{ color: "tomato" }}
                          >
                            Selecciona una ubicación.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles.indicatorMobile}>
          <SalaryIndicator
            {...{
              salary,
              calculatorType,
              country,
              salaryImp,
              isChecked,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryList;
