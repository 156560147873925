import { FC } from "react";
import clsx from "clsx";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Link } from "react-router-dom";
import {
  // HeaderNotificationsMenu,
  HeaderUserMenu,
  // Search,
  // QuickLinks,
  // ThemeModeSwitcher,
} from "../../../partials";
import { useLayout } from "../../core";
import {
  AuthModel,
  getAuth,
  useAuth,
  UserModel,
} from "../../../../app/modules/auth";

const itemClass = "ms-1 ms-lg-3";
// const btnClass =
// "btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px";
// const btnIconClass = "svg-icon-1";
const userAvatarClass = "symbol-30px symbol-md-40px d-flex align-items-center";

interface IProfileBtn {
  isAuth: AuthModel | undefined;
  user: UserModel | undefined;
}

const ProfileBtn: FC<IProfileBtn> = ({ isAuth, user }) => {
  const NameComp = () => {
    return (
      <div
        className="pe-5"
        style={{
          overflow: "hidden",
        }}
      >
        <p className="text-end m-0 fw-bold" style={{ color: "#B5B5C3" }}>
          {isAuth ? user?.fullname : "¿Eres nuevo por aquí?"}
        </p>
        <p
          className="text-end m-0"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {isAuth ? user?.email : "Regístrate o Inicia sesión"}
        </p>
      </div>
    );
  };

  return isAuth ? (
    <div
      className={clsx("d-flex align-items-center", itemClass)}
      id="kt_header_user_menu_toggle"
    >
      <div
        className={clsx("cursor-pointer symbol d-flex", userAvatarClass)}
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <NameComp />
        <img
          alt="avatar"
          src={
            ["", undefined].includes(user?.pic)
              ? toAbsoluteUrl("/media/avatars/blank.png")
              : user?.pic
          }
        />
      </div>
      <HeaderUserMenu />
    </div>
  ) : (
    <div className={clsx("d-flex align-items-center", itemClass)}>
      <Link to={"/auth"}>
        <div className={clsx("cursor-pointer symbol d-flex", userAvatarClass)}>
          <NameComp />
          <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="avatar" />
        </div>
      </Link>
    </div>
  );
};

const Topbar: FC = () => {
  const { config } = useLayout();
  const isAuth = getAuth();
  const { currentUser } = useAuth();

  return (
    <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
      {/* Search 
      <div className={clsx("d-flex align-items-stretch", itemClass)}>
        <Search />
      </div>
      */}

      {/* Activities */}
      {/*
      <div className={clsx("d-flex align-items-center", itemClass)}>
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary btn-custom",
            btnClass
          )}
          id="kt_activities_toggle"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen032.svg"
            className={btnIconClass}
          />
        </div>
      </div>
      */}

      {/* NOTIFICATIONS
      <div className={clsx("d-flex align-items-center", itemClass)}>
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary position-relative ",
            btnClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen022.svg"
            className={btnIconClass}
          />
        </div>
      <HeaderNotificationsMenu />
      </div>
      */}

      {/* CHAT
      <div className={clsx("d-flex align-items-center", itemClass)}>
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary btn-custom position-relative",
            btnClass
          )}
          id="kt_drawer_chat_toggle"
        >
          <KTSVG
            path="/media/icons/duotune/communication/com012.svg"
            className={btnIconClass}
          />

          /// comentado 
          <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
          /// comentado 
          
         </div>
       </div>
      */}

      {/* Quick links */}
      {/*
      <div className={clsx("d-flex align-items-center", itemClass)}>
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary btn-custom",
            btnClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen025.svg"
            className={btnIconClass}
          />
        </div>
        <QuickLinks />
      </div>
      */}

      {/* begin::Theme mode */}
      {/*
      <div className={"d-flex align-items-center ms-1 ms-lg-3"}>
        <ThemeModeSwitcher toggleBtnClass="btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" />
      </div>
      */}
      {/* end::Theme mode */}

      {/* begin::User */}
      <ProfileBtn isAuth={isAuth} user={currentUser} />

      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
